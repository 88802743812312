import { useState } from "react";
import { logger } from "../../../core/logging/logger";
import { transactionManager } from "../../../core/service/services";
import { useObservable } from "../../../utils/observable";
import { useAsyncEffect } from "../../../utils/utils";
import { AccountingTransaction } from "./transaction";

export interface Transaction {
  transaction?: AccountingTransaction;
  loading: boolean;
  error: string | null;
  fetchFromSD: boolean;
  refresh: () => void;
}

export function useTransaction(
  accountId: string | undefined,
  transactionId: string,
  loadAtStartup = true,
): Transaction {
  const transaction = useObservable<AccountingTransaction | undefined>(transactionManager.transaction);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const refresh = async () => {
    if (accountId && transactionId) {
      setLoading(true);
      try {
        await transactionManager.fetch(accountId, transactionId);
      } catch (e) {
        setError(e.response.data.error.message);
        logger.debug("error fetching spendings", e);
      } finally {
        setLoading(false);
      }
    }
  };

  useAsyncEffect(async () => {
    if (loadAtStartup) {
      await refresh();
    }
  }, []);

  return { transaction, refresh, loading, error };
}
