import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { AuthenticationManager } from "../../domains/authentication/authentication-manager";
import { ErrorStore } from "../../error-store";
import { DebugInterface } from "../debug/debug-interface";
import { HttpService } from "./http-service";
import { addAuthorizationInterceptor } from "./interceptors/add-authorization-interceptor";
import { addBaseUrlInterceptor } from "./interceptors/add-base-url-interceptor";
import { addSecuredCookieInterceptor } from "./interceptors/add-secured-cookie-interceptor";
import { addUserAgentInterceptor } from "./interceptors/add-user-agent-interceptor";
import { debugResponseInterceptor } from "./interceptors/debug-response-interceptor";
import { addRequestInterceptor, addResponseInterceptor } from "./interceptors/interceptor";
import { isConnectedCheckInterceptor } from "./interceptors/is-connected-check-interceptor";
import { logResponseInterceptor } from "./interceptors/log-response-interceptor";
import { renewTokenInterceptor } from "./interceptors/renew-token-interceptor";
import { SecuredCookiesService } from "./secured-cookies-service";
import { UserAgentService } from "./user-agent-service";

export class ConnectedApiService {
  public instance: AxiosInstance;

  public constructor(
    private errorStore: ErrorStore,
    private httpService: HttpService,
    private authenticationManager: AuthenticationManager,
    private customUserAgentService: UserAgentService | null,
    private securedCookiesService?: SecuredCookiesService,
    private debugIntercept?: DebugInterface | undefined,
  ) {
    this.instance = axios.create();
    addRequestInterceptor(this.instance, addBaseUrlInterceptor());
    if (this.securedCookiesService) {
      addRequestInterceptor(this.instance, addSecuredCookieInterceptor(this.securedCookiesService));
    }
    if (this.customUserAgentService) {
      addRequestInterceptor(this.instance, addUserAgentInterceptor(this.customUserAgentService));
    }
    addRequestInterceptor(this.instance, addAuthorizationInterceptor(this.authenticationManager));
    addRequestInterceptor(this.instance, isConnectedCheckInterceptor(this.authenticationManager));
    addResponseInterceptor(
      this.instance,
      renewTokenInterceptor(this.httpService, this.authenticationManager, this.securedCookiesService),
    );

    if (this.debugIntercept) {
      addResponseInterceptor(this.instance, debugResponseInterceptor(debugIntercept));
    }
    addResponseInterceptor(this.instance, logResponseInterceptor);
    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if ((error.response && error.response.status === 429) || error.code == "ERR_NETWORK") {
          this.errorStore.error.set(error);
          return Promise.resolve();
        }
        return Promise.reject(error);
      },
    );
  }

  public async buildAuthenticatedRequest(url: string | undefined) {
    let request: AxiosRequestConfig = { url };

    const baseUrlInterceptor = addBaseUrlInterceptor();
    if (baseUrlInterceptor.onFulfilled) {
      request = await baseUrlInterceptor.onFulfilled({ url });
    }

    const authorizationInterceptor = addAuthorizationInterceptor(this.authenticationManager);
    if (authorizationInterceptor.onFulfilled) {
      request = await authorizationInterceptor.onFulfilled(request);
    }

    return request;
  }
}
