import React from "react";
import styled from "styled-components";
import { FormTextInput } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { escapeHtmlChars } from "../../../../shared/utils/string";
import { TextInput } from "../forms/text-input";
import { DisableValueText } from "./disable-value-text";

export interface TextFieldProps {
  input: FormTextInput;
  hasError?: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  innerStyle?: React.CSSProperties;
  multiline?: boolean;
}

export const TextField: React.FC<TextFieldProps> = ({
  input,
  disabled,
  hasError,
  onChange,
  placeholder,
  innerStyle,
  multiline,
}) => {
  const { formatMessage } = useIntl();

  const handleOnChange = (value: string) => {
    const text = escapeHtmlChars(value);
    onChange?.(text);
  };

  return input.disabled || disabled ? (
    <DisableValueText>{input.value}</DisableValueText>
  ) : (
    <StyledTextInput
      required={input.required}
      value={input.value ?? ""}
      onChange={(e) => handleOnChange(e.target.value)}
      placeholder={placeholder ? placeholder : formatMessage("billFormScreen.textInputPlaceholder")}
      hasError={hasError ? hasError : null}
      style={innerStyle}
      multiline={multiline}
    />
  );
};

const StyledTextInput = styled(TextInput)``;
