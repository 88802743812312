import React, { SVGProps } from "react";

export const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M9 8.25a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM2.25 15.15c.291-3.58 3.196-5.4 6.738-5.4 3.59 0 6.54 1.72 6.76 5.4.01.147 0 .6-.563.6H2.795c-.187 0-.56-.406-.545-.6z"
      fill={props.color ?? "#8F8F94"}
    />
  </svg>
);
