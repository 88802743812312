import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Amount } from "../../../../../shared/core/amount/amount";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { spendingsManager } from "../../../../../shared/core/service/services";
import { BaseSpendingCategory } from "../../../../../shared/domains/accounting-transaction/transaction/categorization";
import { AdditionalDataValue } from "../../../../../shared/domains/accounting-transaction/transaction/spending";
import { SpendingLinks } from "../../../../../shared/domains/accounting-transaction/transaction/spending-links";
import { AccountingTransaction } from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import { TransactionLinks } from "../../../../../shared/domains/accounting-transaction/transaction/transaction-links";
import { useCategorizations } from "../../../../../shared/domains/accounting-transaction/transaction/use-categorizations";
import { getUrlFromLink, UrlLink } from "../../../../../shared/domains/BaseUrl";
import { isDefined } from "../../../../../shared/utils/assert";
import { DefaultButton } from "../../../common/buttons/default-button";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../common/error-message";
import { AmountInput } from "../../../common/forms/amount-input";
import FormLabel from "../../../common/forms/form-label";
import { SelectInput } from "../../../common/forms/select-input";
import { TextInput } from "../../../common/forms/text-input";
import { PlusIconSmall } from "../../../common/svg/plus-icon";
import { theme } from "../../../styles/theme";
export interface BlankSpending {
  id?: string;
  category: BaseSpendingCategory | null;
  subcategory: BaseSpendingCategory | null;
  label: string;
  amount: Amount;
  additionalData?: AdditionalDataValue[];
  links?: UrlLink[];
}

type SpendingFormProps = {
  spending: BlankSpending;
  transaction: AccountingTransaction;
  onSuccess: () => void;
};

const emptyAdditionalData = { key: "", value: null };

export default function SpendingForm(props: SpendingFormProps) {
  const { formatMessage } = useIntl();
  const { transaction, spending, onSuccess } = props;
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = React.useState<BlankSpending>({
    ...cloneDeep(spending),
    additionalData:
      spending?.additionalData && spending.additionalData.length
        ? [...spending?.additionalData]
        : [emptyAdditionalData],
  });

  const { categories, additionalData } = useCategorizations();

  const subcategories = value.category
    ? categories?.find((category) => category.id === value.category?.id)?.subCategories || []
    : [];

  useEffect(() => {
    setValue({
      ...cloneDeep(spending),
      additionalData:
        spending?.additionalData && spending.additionalData.length
          ? [...spending?.additionalData]
          : [emptyAdditionalData],
    });
  }, [spending]);

  const isDataValid = () => {
    if (!isDefined(value.category?.id) || !isDefined(value.subcategory?.id) || !isDefined(value.label)) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (transaction) {
      setError("");
      setIsLoading(true);
      try {
        if (value.id) {
          const dataValues = value.additionalData?.map((data) => ({ key: data.key, value: data.value || null })) || [];
          const dataValueToDelete = spending.additionalData
            ?.filter((data) => !dataValues.some((value) => value.key === data.key))
            .map((data) => data.key);
          if (dataValueToDelete && dataValueToDelete.length > 0) {
            dataValueToDelete.forEach((key) => {
              dataValues.push({ key, value: null });
            });
          }
          const url = getUrlFromLink(spending.links, SpendingLinks.Update);
          await spendingsManager.updateSpendingDetails(
            transaction.id,
            value.id,
            {
              label: value.label != spending.label ? value.label : undefined,
              categoryId: value.category?.id || undefined,
              subcategoryId: value.subcategory?.id || undefined,
              amount: value.amount !== spending.amount ? value.amount : undefined,
              additionalData: dataValues.length > 0 ? dataValues.filter((d) => !!d.key) : undefined,
            },
            url,
          );
        } else if (value.category?.id && value.subcategory?.id) {
          const dataValues = value.additionalData?.filter((dataValue) => dataValue.key.length > 0) || [];
          if (!isDataValid()) {
            return;
          }
          const url = getUrlFromLink(transaction.links, TransactionLinks.AddSpending);
          await spendingsManager.addSpendingDetails(
            transaction.id,
            {
              categoryId: value.category?.id,
              subcategoryId: value.subcategory?.id,
              label: value.label,
              amount: value.amount,
              additionalData: dataValues.length > 0 ? dataValues.filter((d) => !!d.key) : undefined,
            },
            url,
          );
        }
        onSuccess();
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.id]: e.target.value,
    });
  };

  const handleValueChange = (val, key: keyof BlankSpending) => {
    setValue({
      ...value,
      [key]: val,
      ...(key === "category" && { subcategory: null }),
    });
  };

  const handleAddAdditionalData = () => {
    const additionalData = value.additionalData || [];
    setValue({
      ...value,
      additionalData: [...additionalData, emptyAdditionalData],
    });
  };

  const handleAdditionalDataChange = (val, index: number, key: keyof AdditionalDataValue) => {
    const additionalData = value.additionalData ? [...value.additionalData] : [];
    additionalData[index] = {
      ...additionalData[index],
      ...(key === "value" && { value: val }),
      ...(key === "key" && { ...val }),
    };
    setValue({
      ...value,
      additionalData: [...additionalData],
    });
  };

  const availableAdditionalData = (selectedAdditionalData?: AdditionalDataValue) => {
    const availableAdditionalData = additionalData?.filter(
      (data) => !value.additionalData?.some((val) => val.key === data.key && val.key !== selectedAdditionalData?.key),
    );
    return [{ value: null, label: "-", key: "" }, ...availableAdditionalData];
  };

  const canAddAnotherAdditionalData = value.additionalData
    ? value.additionalData?.length < additionalData.length
    : true;
  const isLastAdditionalDataSet =
    value.additionalData && value.additionalData.length > 0
      ? !!value.additionalData[value.additionalData.length - 1].key &&
        !!value.additionalData[value.additionalData.length - 1].value
      : true;

  const addDisable = !isDataValid() || !isLastAdditionalDataSet || !canAddAnotherAdditionalData;

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        ...(isLoading && { opacity: 0.5, pointerEvents: "none" }),
      }}
    >
      <Title>
        {formatMessage(
          !value.id ? "justifyScreen.spendings.addModal.title" : "justifyScreen.spendings.addModal.editTitle",
        )}
      </Title>
      <Message>{formatMessage("justifyScreen.spendings.addModal.message")}</Message>
      <FormFieldWrapper>
        <FormLabel htmlFor="label" label="justifyScreen.spendings.addForm.titleLabel" required />
        <TextInput id="label" value={value.label} required onChange={handleChange} />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormLabel htmlFor="amount" label="justifyScreen.spendings.addForm.amountLabel" required />
        <AmountInput
          variant="big"
          id="amount"
          value={value.amount}
          onChange={(val) => handleValueChange(val, "amount")}
          required
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormLabel htmlFor="category" label="justifyScreen.spendings.addForm.categoryLabel" required />
        <CategorySelectInput
          innerId="category"
          value={value.category}
          options={[{ id: null, label: "-" }, ...categories]}
          onChange={(val) => handleValueChange(val, "category")}
          itemRenderer={(v) => v?.label ?? v?.id}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormLabel htmlFor="subcategory" label="justifyScreen.spendings.addForm.subcategoryLabel" required />
        <CategorySelectInput
          disabled={!value.category}
          innerId="subcategory"
          value={value.subcategory}
          options={[{ id: null, label: "-" }, ...subcategories]}
          onChange={(val) => handleValueChange(val, "subcategory")}
          itemRenderer={(v) => v?.label ?? v?.id}
        />
      </FormFieldWrapper>
      {!!value.category && !!value.subcategory && (
        <>
          {value.additionalData?.map((data, index) => (
            <FormFieldWrapper key={index}>
              <FormLabel
                htmlFor={`additional_data_key_${index}`}
                label="justifyScreen.spendings.addForm.additionalDataLabel"
              />
              <AdditionalDataStyledSelectInput
                innerId={`additional_data_key_${index}`}
                value={data}
                options={availableAdditionalData(data)}
                onChange={(val) => handleAdditionalDataChange(val, index, "key")}
                itemRenderer={(v) => v?.label ?? v?.key}
              />
              <StyledAdditionalDataTextInput
                id={`additional_data_value_${index}`}
                value={data.value || ""}
                onChange={(e) => handleAdditionalDataChange(e.target.value, index, "value")}
              />
            </FormFieldWrapper>
          ))}
          <AddButton disabled={addDisable} onClick={handleAddAdditionalData}>
            <PlusIconWrapper
              style={{
                ...(addDisable && {
                  opacity: 0.5,
                }),
              }}
            >
              <PlusIconSmall />
            </PlusIconWrapper>
            Ajouter une donnée additionnelle
          </AddButton>
        </>
      )}
      {!!error && <StyledErrorMessage>{error}</StyledErrorMessage>}
      <SubmitButton disabled={!isDataValid()} size="M" type="submit">
        {value.id ? "Enregistrer" : "Valider"}
      </SubmitButton>
    </form>
  );
}

const PlusIconWrapper = styled.div`
  margin-right: 0.5rem;
  width: 35px;
  height: 35px;
  background-color: ${theme.colors.gray[100]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddButton = styled(DefaultButton)`
  margin-top: 1rem;
`;

const Title = styled.div`
  ${theme.mediumText};
  color: ${theme.mainColor};
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: left;
`;

const Message = styled.div`
  ${theme.text};
  color: black;
  line-height: 1.25rem;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 40px;
`;

const FormFieldWrapper = styled.div`
  margin-bottom: 1rem;
`;

const AdditionalDataStyledSelectInput = styled(SelectInput<AdditionalDataValue>)`
  width: 100%;
`;

const CategorySelectInput = styled(SelectInput<BaseSpendingCategory>)`
  width: 100%;
`;

const StyledAdditionalDataTextInput = styled(TextInput)`
  margin-top: 1rem;
`;

const SubmitButton = styled(PrimaryButton)`
  min-width: 250px;
  margin: 20px auto 0;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 16px;
`;
