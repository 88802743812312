import { AccessToken, JWTToken, Session } from "../authentication/session";

import JwtDecode from "jwt-decode";
import { BasicSecureStorage } from "../../../mobile/core/cache/basic-secure-storage";
import { SecuredCookiesService } from "../../core/net/secured-cookies-service";
import { Observable } from "../../utils/observable";
import { TokenResultDto } from "../authentication/oauth-service";
import { SessionBuilder } from "../authentication/session-builder";
import { FeaturesManager } from "../features/features-manager";
import { ServiceDomainService } from "./service-domain-service";

export class ServiceDomainManager {
  private sessionBuilder = new SessionBuilder(this.securedCookiesService);
  public session = new Observable<Session>(this.sessionBuilder.build());
  public isServiceDomainTokenValid = new Observable<boolean>(false);
  public serviceDomainToken = new Observable<TokenResultDto | null>(null);

  public constructor(
    private serviceDomainService: ServiceDomainService,
    private featuresManager: FeaturesManager,
    private storage: BasicSecureStorage,
    private securedCookiesService?: SecuredCookiesService,
  ) {
    this.serviceDomainToken.onChange.add((token) => {
      if (token) {
        const decodedToken: JWTToken = JwtDecode(token.access_token);
        this.featuresManager.updateFeatureWithServiceDomain(decodedToken.scopes ?? []);
      }
    });
  }

  public async getToken() {
    try {
      const authorizeResult = await this.serviceDomainService.getToken();
      this.serviceDomainToken.set(authorizeResult);
      this.isServiceDomainTokenValid.set(true);
      return authorizeResult;
    } catch (e) {
      throw e;
    }
  }

  public getAccessToken(): AccessToken | null {
    const token = this.serviceDomainToken.get() ?? null;
    if (!token) {
      return null;
    }
    return {
      type: token.token_type,
      value: token.access_token,
      expirationDate: new Date(token.expires_in),
    };
  }
}
