import { Validator } from "../core/data-forms/validator";

export const caseInsensitiveIncludes = function (value: string | undefined, search: string): boolean {
  return (
    value !== undefined && value.match(new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "i")) !== null
  );
};

export function escapeSpecialCharsAndEmojis(text: string): string {
  return text
    .replace(/[^\w\s]/gi, "")
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      "",
    );
}

export function escapeHtmlChars(text: string): string {
  return text.replace(/[&<>"'`=\/]/g, "");
}
export function escapeWithValidation(text: string, validationRule: Validator): string {
  return text.replace(/[&<>"'`=\/]/g, "");
}

export function formatToSepa(input) {
  let output = "";
  const regex = new RegExp("[a-zA-Z|0-9| |/|-|-|?|:|(|)|.|,|'|‘|’|+]");
  for (const char of input) {
    if (char.match(regex)) {
      output += char;
    }
  }
  return output;
}

export function stringToRegex(str) {
  const main = str.match(/\/(.+)\/.*/);
  const options = str.match(/\/.+\/(.*)/);
  if (main && main.length > 1 && options && options.length > 1) {
    return new RegExp(main[1], options[1]);
  }
  return null;
}
