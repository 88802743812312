import React, { useMemo } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { useBillIssuer } from "../../../../shared/domains/bills/use-bill-issuer";
import { collectIssuersWithTopLevelCategory } from "../../../../shared/domains/bills/utils";
import { Path } from "../../../core/routing/path";
import { TertiaryLink } from "../../common/buttons/tertiary-button";
import { ErrorMessage } from "../../common/error-message";
import { PageHeader } from "../../common/nav/page-header";
import { PageSectionTitle } from "../../common/nav/page-section-title";
import { MainColorSpinner } from "../../common/spinner";
import { shadows, theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { BillIssuerView } from "./components/bill-issuer-view";

export const BillReferencesScreen = () => {
  const { formatMessage } = useIntl();
  const { billIssuers: billIssuersAndCategories, loading, refreshing, error, billIssuerColors } = useBillIssuer();

  const issuers = useMemo(
    () =>
      billIssuersAndCategories
        ? collectIssuersWithTopLevelCategory(billIssuersAndCategories).filter((issuer) => issuer.references)
        : null,
    [billIssuersAndCategories],
  );

  const colors = useMemo(
    () =>
      new Map<string, string>(
        billIssuersAndCategories
          ? billIssuersAndCategories
              .filter((issuerOrCategory) => issuerOrCategory.type === "CATEGORY")
              .map((category, index) => [category.id, billIssuerColors[index % billIssuerColors.length]])
          : null,
      ),
    [billIssuersAndCategories, billIssuerColors],
  );

  return (
    <ReferenceSection>
      <PageHeader>
        <ReferenceSectionTitle>{formatMessage("billReferencesScreen.title")}</ReferenceSectionTitle>
        <GoBackToServicesLink to={Path.BillIssuers}>
          {formatMessage("billReferencesScreen.services")}
        </GoBackToServicesLink>
      </PageHeader>
      {issuers && issuers.length > 0 ? (
        issuers.map((issuer) => (
          <IssuerView
            key={issuer.id}
            issuer={issuer}
            topLevelCategory={issuer.topLevelCategory}
            color={colors.get(issuer.topLevelCategory?.id ?? "") || "#000000"}
            withReference
          />
        ))
      ) : loading || refreshing ? (
        <StyledSpinner />
      ) : error ? (
        <StyledErrorMessage>{formatMessage("billReferencesScreen.loadBillReferencesError")}</StyledErrorMessage>
      ) : (
        <EmptyListMessage>{formatMessage("billReferencesScreen.emptyRecipientList")}</EmptyListMessage>
      )}
    </ReferenceSection>
  );
};

const ReferenceSection = styled.section`
  align-self: stretch;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ReferenceSectionTitle = styled(PageSectionTitle)`
  margin-right: 10px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 30px;
`;

const EmptyListMessage = styled.span`
  ${theme.mediumText}
  font-size: 0.875rem;
  color: #b1b1b1;
  margin-top: 30px;
  text-align: center;
`;

const StyledSpinner = styled(MainColorSpinner)`
  margin: 40px auto 0 auto;
`;

const GoBackToServicesLink = styled(TertiaryLink)`
  text-align: center;
  margin-right: 10px;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-bottom: 20px;
  }
`;

const IssuerView = styled(BillIssuerView)`
  position: relative;
  margin: 10px 0;
  border-radius: 10px;
  ${shadows.medium};
  background-color: #ffffff;
`;
