import { CreditScope, SavingsScope, Scope, ServiceDomainScope, SharedServiceDomainScope } from "./scope";

import moment from "moment";

export type Session = ConnectedSession | NotConnectedSession;

export interface ConnectedSession {
  accessToken: AccessToken;
  refreshToken: string;
  scopes: (Scope | ServiceDomainScope | SharedServiceDomainScope | SavingsScope)[];
  isConnected: true;
  clientId?: string | null;
}

export interface NotConnectedSession {
  accessToken: AccessToken | null;
  scopes: (Scope | ServiceDomainScope | SharedServiceDomainScope | SavingsScope)[];
  isConnected: false;
  clientId?: string | null;
}

export interface AccessToken {
  value: string;
  type: string;
  expirationDate: Date;
}

export interface JWTToken {
  scopes: (Scope | ServiceDomainScope | SharedServiceDomainScope)[];
  extra: {
    login: string;
    tenant: string;
  };
  user: {
    id: string;
    profile: {
      id: string;
      name: string;
    }[];
  };
}

export interface JWTServiceDomainToken {
  scopes: (CreditScope | SavingsScope | SharedServiceDomainScope)[];
  extra: {
    login: string;
    tenant: string;
  };
  user: {
    id: string;
    profile: {
      id: string;
      name: string;
    }[];
  };
}

export function sessionHasScope(
  session: Session,
  scope: Scope | ServiceDomainScope | SharedServiceDomainScope | SavingsScope,
) {
  return session.scopes.includes(scope);
}

export function isAccessTokenExpired(accessToken: AccessToken): boolean {
  return moment(accessToken.expirationDate).isBefore(moment());
}
