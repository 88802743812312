import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  accountStatementService,
  featuresManager,
  i18NManager,
  savingsManager,
} from "../../../../shared/core/service/services";
import { Account, AccountStatus, AccountType } from "../../../../shared/domains/account/account";
import { shadows, theme } from "../../styles/theme";

import { Amount } from "../../../../shared/core/amount/amount";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { AccountStatement } from "../../../../shared/domains/account-statements/account-statement";
import { statusColors } from "../../../../shared/domains/account/account-status-labels-and-colors";
import { accountTypeLabels } from "../../../../shared/domains/account/account-types-labels";
import { FeesStatement } from "../../../../shared/domains/fees-statements/fees-statement";
import { isDefined } from "../../../../shared/utils/assert";
import { useObservable } from "../../../../shared/utils/observable";
import { Paginated } from "../../../../shared/utils/pagination";
import { useAsyncEffect } from "../../../../shared/utils/utils";
import { ACCOUNT_DETAIL_MODAL_ID } from "../../../core/modal/modal-id";
import { AccountStatusView } from "../../common/account-status-view";
import { AmountText } from "../../common/amount-text";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import { ScrollableDiv } from "../../common/modal/scrollable-div";
import { Spinner } from "../../common/spinner";
import { GenerateAccountStatementButton } from "../account-transactions/components/generate-account-statement-button.tsx";
import { ClipboardInformationRow } from "./components/clipboard-information-row";
import { CollapsableRowsSection } from "./components/collapsable-section";
import { DownloadBankAccountDetails } from "./components/download-bank-details-button";
import { StatementRow } from "./components/statement-row";

export interface AccountDetailsModalProps {
  account: Account;
}

export const AccountDetailsModal = (props: AccountDetailsModalProps) => {
  const { account } = props;
  const { formatMessage, formatDate, formatAmount } = useIntl();

  const statusMessage = statusMessageKey(account.status);
  const features = useObservable(featuresManager.features);
  const savingsDetails = useObservable(savingsManager.savingDetails);
  const accountIsSavings = account?.type === "SAVINGS";
  const userLanguage = useObservable(i18NManager.localeTag);

  useAsyncEffect(async () => {
    if (accountIsSavings) {
      savingsManager.getSavingsDetails(account.id);
    }
  }, [account]);

  return (
    <AccountDetailsModalContainer
      className="account-modal-container"
      contentClassName="content"
      closeButton
      id={ACCOUNT_DETAIL_MODAL_ID}
    >
      <ContentContainer>
        <AccountTypeText>
          {accountIsSavings
            ? savingsDetails.product?.index.names[userLanguage]
            : formatMessage(`account.${accountTypeLabels[account.type]}`)}
        </AccountTypeText>
        <AccountDetailsModalContent className="scroll-container-without-shadow">
          <StyledAccountStatus account={account} />
          <AccountAvailableBalanceContainer>
            <AccountAvailableBalanceTitle>
              {formatMessage("accountDetails.availableBalanceTitle")}
            </AccountAvailableBalanceTitle>
            <AccountAvailableBalance amount={account.availableBalance} />
          </AccountAvailableBalanceContainer>
          {statusMessage && (
            <StatusMessage color={statusColors[account.status]}>{formatMessage(statusMessage)}</StatusMessage>
          )}
          {account.type === AccountType.Dedicated && isDefined(account.merchant) ? (
            <DedicatedAccountContainer>
              <DedicatedAccountMessage>{formatMessage("accountDetails.dedicatedMessage")}</DedicatedAccountMessage>
              <DedicatedAccountMerchant>{account.merchant}</DedicatedAccountMerchant>
            </DedicatedAccountContainer>
          ) : null}
          <AccountBalanceContainer>
            <AccountBalanceTitleContainer>
              <AccountBalanceTitle>{formatMessage("accountDetails.balanceTitle")}</AccountBalanceTitle>
              <AccountBalance amount={account.balance} />
            </AccountBalanceTitleContainer>
            <AccountBalanceMessage>{formatMessage("accountDetails.balanceMessage")}</AccountBalanceMessage>
          </AccountBalanceContainer>
          <InformationContainer>
            <StyledClipboardInformationRow title={formatMessage("accountDetails.accountIdTitle")} value={account.id} />
            {isDefined(account.iban) && (
              <StyledClipboardInformationRow title={formatMessage("accountDetails.ibanTitle")} value={account.iban} />
            )}
            {isDefined(account.bic) && (
              <StyledClipboardInformationRow title={formatMessage("accountDetails.bicTitle")} value={account.bic} />
            )}
            {accountIsSavings && savingsDetails?.startDate && (
              <StyledClipboardInformationRow
                title={formatMessage("accountDetails.accountStartDate")}
                value={formatDate(savingsDetails.startDate, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                })}
                noCopy
              />
            )}
            {accountIsSavings &&
              savingsDetails?.product?.balanceBoundaries &&
              savingsDetails.product?.balanceBoundaries.maximum &&
              savingsDetails.product?.balanceBoundaries.maximum.value && (
                <StyledClipboardInformationRow
                  title={formatMessage("accountDetails.maximumBoundaries")}
                  value={formatAmount(savingsDetails.product?.balanceBoundaries.maximum)}
                />
              )}
            {features.sdaAccountView && isDefined(account.iban) && <DownloadBankAccountDetails account={account} />}
            {isDefined(account.externalData) &&
              Object.entries(account.externalData).map(([title, value], index) => (
                <StyledClipboardInformationRow key={index} title={title} value={value} />
              ))}
          </InformationContainer>
          {accountIsSavings && savingsDetails.initialAmount && (
            <CollapsableRowsSection
              rows={[
                {
                  title: formatMessage("accountDetails.initialAmountLabel"),
                  value: formatAmount(
                    new Amount(savingsDetails.initialAmount.amount, savingsDetails.initialAmount.currency),
                  ),
                },
                {
                  title: formatMessage("accountDetails.endDateLabel"),
                  value: formatDate(savingsDetails.endDate, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  }),
                },
                {
                  title: formatMessage("accountDetails.durationLabel"),
                  value: `${savingsDetails.durationValue} ${formatMessage(
                    `accountDetails.monthsLabels.${savingsDetails.cycleFrequency}`,
                  )}`,
                },
              ]}
            />
          )}
          {accountIsSavings &&
            savingsDetails.product?.index?.rate?.value &&
            savingsDetails.product?.index?.rate?.type && (
              <CollapsableRowsSection
                rows={[
                  {
                    title: formatMessage("accountDetails.rateValueLabel"),
                    value: `${savingsDetails.product?.index?.rate?.value} % (${savingsDetails.product.index?.rate?.type})`,
                  },
                  {
                    title: formatMessage("accountDetails.indexCodeLabel"),
                    value: savingsDetails.product?.index?.code,
                  },
                  {
                    title: formatMessage("accountDetails.rateStartDateLabel"),
                    value: formatDate(savingsDetails.product?.index?.rate?.startDate, {
                      day: "2-digit",
                      month: "2-digit",
                      year: "2-digit",
                    }),
                  },
                ]}
              />
            )}
          {accountIsSavings && savingsDetails.accruedInterests && (
            <CollapsableRowsSection
              rows={[
                {
                  title: formatMessage("accountDetails.accruedInterestsLabel"),
                  value: formatAmount(
                    new Amount(savingsDetails.accruedInterests?.amount, savingsDetails.accruedInterests?.currency),
                  ),
                },
                {
                  title: formatMessage("accountDetails.startDateLabel"),
                  value: formatDate(savingsDetails.startDate, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  }),
                },
                {
                  title: formatMessage("accountDetails.forecastInterestsLabel"),
                  value: formatAmount(
                    new Amount(savingsDetails.forecastInterests?.amount, savingsDetails.forecastInterests?.currency),
                  ),
                },
                {
                  title: formatMessage("accountDetails.initialAERLabel"),
                  value: `${(+savingsDetails.initialAer * 100).toFixed(2)} %`,
                },
              ]}
            />
          )}
          <div style={{ marginTop: 20 }} />
          <Statements account={account} />
          {!accountIsSavings && <FeesStatements account={account} />}
        </AccountDetailsModalContent>
      </ContentContainer>
    </AccountDetailsModalContainer>
  );
};

interface StatementsProps {
  account: Account;
}
export const Statements = (props: StatementsProps) => {
  const { account } = props;
  const [statements, setStatements] = useState<Paginated<AccountStatement> | null>(null);
  useEffect(() => {
    accountStatementService.fetchStatements(account.id).then((result) => setStatements(result));
  }, [account.id]);
  const { formatMessage } = useIntl();
  const features = useObservable(featuresManager.features);

  if (statements === null) {
    return (
      <StatementContainer>
        <StatementTitle>{formatMessage("accountsStatements.title")}</StatementTitle>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </StatementContainer>
    );
  } else if (statements.items.length > 0) {
    return (
      <StatementContainer>
        <StatementTitle>{formatMessage("accountsStatements.title")}</StatementTitle>
        {features.sdaAccountView ? (
          <StyledGenerateAccountButton>
            <GenerateAccountStatementButton accountId={account.id} />
          </StyledGenerateAccountButton>
        ) : null}
        {statements.items.map((it) => (
          <StyledStatementRow key={it.id} statement={it} account={account} />
        ))}
      </StatementContainer>
    );
  }
  return (
    <StatementContainer>
      <StatementTitle>{formatMessage("accountsStatements.title")}</StatementTitle>
      <NoStatementAvailable>{formatMessage("accountsStatements.emptyStatementList")}</NoStatementAvailable>
    </StatementContainer>
  );
};

interface FeesStatementsProps {
  account: Account;
}
export const FeesStatements = (props: FeesStatementsProps) => {
  const { account } = props;
  const [statements, setStatements] = useState<Paginated<FeesStatement> | null>(null);
  useEffect(() => {
    accountStatementService.fetchFeesStatements(account.id).then((result) => setStatements(result));
  }, [account.id]);
  const { formatMessage } = useIntl();

  if (statements === null) {
    return (
      <StatementContainer>
        <StatementTitle>{formatMessage("feesStatements.title")}</StatementTitle>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </StatementContainer>
    );
  } else if (statements.items.length > 0) {
    return (
      <StatementContainer>
        <StatementTitle>{formatMessage("feesStatements.title")}</StatementTitle>
        {statements.items.map((it) => (
          <StyledStatementRow key={it.id} variant="fee" statement={it} account={account} />
        ))}
      </StatementContainer>
    );
  }
  return (
    <StatementContainer>
      <StatementTitle>{formatMessage("feesStatements.title")}</StatementTitle>
      <NoStatementAvailable>{formatMessage("feesStatements.emptyStatementList")}</NoStatementAvailable>
    </StatementContainer>
  );
};
const statusMessageKey = (status: AccountStatus) => {
  switch (status) {
    case AccountStatus.Blocked:
      return "accountDetails.accountStatus.blocked";
    case AccountStatus.CreditBlocked:
      return "accountDetails.accountStatus.creditBlocked";
    case AccountStatus.DebitBlocked:
      return "accountDetails.accountStatus.debitBlocked";
    default:
      return undefined;
  }
};
const AccountDetailsModalContainer = styled(RoundedModalContainer)`
  &.account-modal-container {
    margin-top: 10vh;
  }
  .content {
    width: 600px;
  }
`;
const AccountDetailsModalContent = styled(ScrollableDiv)`{
    &.scroll-container-without-shadow {
        &:before,
        &:after {
            display: none;
        }
    }
}
`;
const ContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  height: 710px;
`;

const AccountTypeText = styled.div`
  ${theme.boldText};
  font-size: 1.375rem;
  margin-left: 30px;
  margin-bottom: 20px;
`;

const StyledAccountStatus = styled(AccountStatusView)`
  position: absolute;
  right: 15px;
  top: 0;
`;

const AccountAvailableBalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  align-self: stretch;
`;

const AccountAvailableBalanceTitle = styled.span`
  ${theme.mediumText};
  font-size: 1rem;
  color: #b1b1b1;
`;

const AccountAvailableBalance = styled(AmountText)`
  font-size: 2rem;
  ${theme.boldText};
  margin-top: 5px;
`;

const StatusMessage = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  text-align: center;
  font-size: 0.875rem;
  ${theme.mediumText}
  margin: 30px 30px 0px 30px;
`;

const DedicatedAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${shadows.medium};
  margin: 15px 20px 15px 20px;
  padding: 25px 15px 25px 15px;
  border-radius: 13px;
  align-items: center;
`;
const DedicatedAccountMessage = styled.div`
  ${theme.text};
  font-size: 1rem;
  color: #b1b1b1;
  text-align: center;
`;

const DedicatedAccountMerchant = styled.div`
  ${theme.boldText};
  font-size: 0.875rem;
  color: ${theme.mainColor};
`;

const AccountBalanceContainer = styled(DedicatedAccountContainer)``;
const AccountBalanceTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  margin-bottom: 10px;
`;
const AccountBalanceTitle = styled.span`
  ${theme.boldText};
`;
const AccountBalance = styled(AmountText)`
  ${theme.boldText};
  color: ${theme.mainColor};
`;
const AccountBalanceMessage = styled.span`
  text-align: center;
  color: #b1b1b1;
  font-size: 0.875rem;
`;

const InformationContainer = styled.div`
  padding: 0 30px 30px 30px;
`;

const StyledClipboardInformationRow = styled(ClipboardInformationRow)`
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;

const StatementContainer = styled.div`
  padding: 0 30px 40px 30px;
`;

const StatementTitle = styled.div`
  ${theme.boldText};
  font-size: 1.125rem;
  margin-bottom: 15px;
`;
const StyledStatementRow = styled(StatementRow)`
  padding: 15px 15px 15px 15px;
  ${shadows.medium};
  border-radius: 13px;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const NoStatementAvailable = styled.span`
  font-size: 0.9375rem;
  color: #b1b1b1;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const StyledGenerateAccountButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
