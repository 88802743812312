import React, { useEffect } from "react";
import styled from "styled-components";
import { FormBoolInput } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { RadioSelector, RadioSelectorType } from "../forms/radio-selector";
import { DisableValueText } from "./disable-value-text";

export const BoolField: React.FC<{
  input: FormBoolInput;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}> = ({ input, onChange, disabled }) => {
  useEffect(() => {
    if (!input.value) {
      onChange?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { formatMessage } = useIntl();

  const values: { id: boolean; label: string }[] = [
    { id: true, label: formatMessage("common.yes") },
    { id: false, label: formatMessage("common.no") },
  ];

  return input.disabled || disabled ? (
    <DisableValueText>{input.label}</DisableValueText>
  ) : (
    <StyledRadioSelector
      innerId={input.id}
      values={new Map<boolean, string>(values.map(({ id, label }) => [id, label]))}
      value={input.value}
      onChange={(value) => onChange && onChange(value || false)}
    />
  );
};

const StyledRadioSelector = styled<RadioSelectorType<boolean | undefined>>(RadioSelector)`
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;
