import React, { SVGProps } from "react";

export const ExternalAccountPlaceholder = (props: SVGProps<SVGSVGElement>) => (
  <svg width="202" height="126" viewBox="0 0 202 126" fill="none" {...props}>
    <rect
      opacity=".34"
      x="4"
      y="38.609"
      width="180"
      height="88.941"
      rx="6.882"
      transform="rotate(-12.233 4 38.609)"
      fill="#EAEAEA"
    />
    <mask id="6bpfju3qua" maskUnits="userSpaceOnUse" x="11" y="18" width="181" height="90">
      <rect x="11.379" y="18.529" width="180" height="88.941" rx="6.882" fill="#fff" />
    </mask>
    <g mask="url(#6bpfju3qua)">
      <g filter="url(#tqpf8ksidb)">
        <rect x="11.379" y="18.529" width="180" height="88.941" rx="6.882" fill="#EDEDED" />
      </g>
      <circle cx="43.144" cy="90.588" r="1.059" fill="#CCC" />
      <circle cx="37.85" cy="90.588" r="1.059" fill="#CCC" />
      <circle cx="32.556" cy="90.588" r="1.059" fill="#CCC" />
      <circle cx="27.262" cy="90.588" r="1.059" fill="#CCC" />
    </g>
    <g opacity=".4">
      <rect x="171.528" y="33.657" width="9.264" height="9.343" rx="3.843" fill="#C8C8C8" />
      <ellipse cx="176.159" cy="38.329" rx="1.667" ry="1.682" fill="#E8E8E8" />
    </g>
    <rect opacity=".3" x="26.203" y="47.235" width="51.882" height="4.235" rx="2.118" fill="#CCC" />
    <rect opacity=".4" x="26.203" y="36" width="84.177" height="4.235" rx="2.118" fill="#C2C2C2" />
    <defs>
      <filter
        id="tqpf8ksidb"
        x=".791"
        y="12.176"
        width="201.176"
        height="110.118"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4.235" />
        <feGaussianBlur stdDeviation="5.294" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.050672 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_406_23864" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_406_23864" result="shape" />
      </filter>
    </defs>
  </svg>
);
