import { useCallback, useMemo, useState } from "react";

import moment from "moment";
import { useIntl } from "../../../core/i18n/use-intl";
import { transactionSearchManager } from "../../../core/service/services";
import { useObservable } from "../../../utils/observable";
import { Account } from "../../account/account";
import { TimeRange } from "../search/timerange";
import { TransactionDirection } from "./transaction-filter";

export const useTransactionFilter = (account: Account | undefined | null) => {
  const timerangeSelection = useObservable(transactionSearchManager.timeRangeSelection);
  const amountRange = useObservable(transactionSearchManager.amountRange);
  const directionSelection = useObservable(transactionSearchManager.directionSelection);

  const filters = useMemo(
    () => ({
      timerange: timerangeSelection,
      amountRange,
      currencyId: account?.balance.currency,
    }),
    [timerangeSelection, amountRange, account],
  );

  const { formattedAmountRange, hasAmountFilter } = useFormattedAmountRange(account);
  const hasDirectionFilter = directionSelection !== TransactionDirection.ALL;
  const hasFilter = hasDirectionFilter || timerangeSelection.timeRange !== TimeRange.Quarter || hasAmountFilter;

  return {
    filters,
    formattedAmountRange,
    hasFilter,
    hasDirectionFilter,
    directionFilter: directionSelection,
    hasDateCustomFilter: timerangeSelection.timeRange === TimeRange.Other,
    fromDate: timerangeSelection.bounds[0]?.toDate(),
    toDate: timerangeSelection.bounds[1]?.toDate(),
    hasAmountFilter,
  };
};
export const useFormattedAmountRange = (account: Account | undefined | null) => {
  const currency = account?.balance.currency;
  const { formatNumber } = useIntl();
  const amountRange = useObservable(transactionSearchManager.amountRange);
  const hasAmountFilter = hasBounds(amountRange);
  const formattedAmountRange = useMemo(
    () =>
      currency
        ? `${formatNumber(amountRange[0] ?? 0, {
            currency: currency as string,
            style: "currency",
            minimumFractionDigits: 0,
          })} - ${
            amountRange[1]
              ? formatNumber(amountRange[1], {
                  currency: currency as string,
                  style: "currency",
                  minimumFractionDigits: 0,
                })
              : "--"
          }`
        : "-- - --",
    [amountRange, currency, formatNumber],
  );
  return { formattedAmountRange, hasAmountFilter };
};
export const useTransactionDateFilter = () => {
  const [startDate, setStartDate] = useState<Date | undefined>(
    transactionSearchManager.timeRangeSelection.get().timeRange === TimeRange.Other
      ? transactionSearchManager.timeRangeSelection.get().bounds[0]?.toDate()
      : undefined,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    transactionSearchManager.timeRangeSelection.get().timeRange === TimeRange.Other
      ? transactionSearchManager.timeRangeSelection.get().bounds[1]?.toDate()
      : undefined,
  );

  const onStartDateChange = useCallback(
    (newStartDate: Date | undefined, onlyOneMonth?: boolean) => {
      setStartDate(newStartDate);
      if (!isValidInterval(newStartDate, endDate)) {
        const monthsToAdd = onlyOneMonth ? 1 : 3;
        setEndDate(moment(newStartDate).add(monthsToAdd, "month").startOf("day").toDate());
      }
    },
    [endDate],
  );

  const onEndDateChange = useCallback(
    (newEndDate: Date | undefined, onlyOneMonth?: boolean) => {
      setEndDate(newEndDate);
      if (!isValidInterval(startDate, newEndDate)) {
        setEndDate(newEndDate);
        const monthsToSubtract = onlyOneMonth ? 1 : 3;
        setStartDate(moment(newEndDate).subtract(monthsToSubtract, "month").add(1, "hour").toDate());
      }
    },
    [startDate],
  );
  return { startDate, endDate, onStartDateChange, onEndDateChange };
};

function hasBounds<T>(bounds: [T | null, T | null]) {
  const [low, high] = bounds;
  return low !== null || high !== null;
}

const isValidInterval = (from: Date | undefined, to: Date | undefined) => {
  if (from === undefined && to === undefined) {
    return true;
  }
  if (to === undefined || from === undefined) {
    return false;
  }
  const startDate = moment(from);
  const endDate = moment(to);
  const isStartDateBeforeEndDate = startDate.isSameOrBefore(endDate);
  return isStartDateBeforeEndDate && endDate.diff(startDate, "month", true) <= 3;
};
