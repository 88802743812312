import React, { HTMLAttributes, useRef, useState } from "react";
import { shadows, theme } from "../../../styles/theme";

import moment from "moment/moment";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { generateAccountStatementService } from "../../../../../shared/core/service/services";
import { useTransactionDateFilter } from "../../../../../shared/domains/accounting-transaction/transaction/use-transaction-filter.ts";
import { useClickOutsideHandler } from "../../../../utils/use-outside-click";
import { RoundActionButton } from "../../../common/buttons/round-button";
import { DateInput } from "../../../common/forms/date-input.tsx";
import { Spinner } from "../../../common/spinner.tsx";
import { CheckIcon } from "../../../common/svg/check-icon";
import { GenerateIcon } from "../../../common/svg/generate-icon.tsx";

interface GenerateButtonProps extends HTMLAttributes<HTMLDivElement> {
  accountId: string;
}

export const GenerateAccountStatement = (props: GenerateButtonProps) => {
  const { children, onClick, active, ...rest } = props;
  return (
    <GenerateButtonContainer {...rest} onClick={onClick} active={active}>
      {children}
    </GenerateButtonContainer>
  );
};

export const GenerateAccountStatementButton = ({ accountId, ...props }: GenerateButtonProps) => {
  const dropdownContentRef = useRef(null);
  useClickOutsideHandler(dropdownContentRef, () => setShowDropdown(false));
  const [showDropdown, setShowDropdown] = useState(false);
  const { formatMessage } = useIntl();
  const { startDate, endDate, onStartDateChange, onEndDateChange } = useTransactionDateFilter();
  const [isLoading, setIsLoading] = useState(false);
  const [isDateChanged, setIsDateChanged] = useState(false);

  const handleStartDateChange = (e) => {
    const date = moment(e.target.value);
    if (date.isValid()) {
      onStartDateChange(date.toDate(), true);
      setIsDateChanged(true);
    }
  };

  const handleEndDateChange = (e) => {
    const date = moment(e.target.value);
    if (date.isValid()) {
      onEndDateChange(date.toDate(), true);
      setIsDateChanged(true);
    }
  };
  const handleDownloadAccountStatement = async () => {
    try {
      setIsLoading(true);
      await generateAccountStatementService.downloadGenerateAccountStatement(accountId, startDate, endDate);
      setShowDropdown(false);
    } catch (error) {
      console.error("Error generating statement", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <GenerateAccountStatement
      {...props}
      onClick={() => {
        setShowDropdown((show) => !show);
      }}
    >
      <StyledGenerateButton active={showDropdown}>
        <GenerateIcon />
        <StyledGenerateLabel>{formatMessage("accountsStatements.generateButton")}</StyledGenerateLabel>
      </StyledGenerateButton>
      <GenerateAccountStatementContent
        ref={dropdownContentRef}
        $isVisible={showDropdown}
        onClick={(e) => e.stopPropagation()}
      >
        <PanelFilterByDate>
          <DateTitle>{formatMessage("transactionFilter.timeRangeSelectionTitle")}</DateTitle>
          <Row>
            <DateCaption>{formatMessage("transactionFilter.timeRange.from")}</DateCaption>
            <DateContainer>
              <StyledDateInput
                mode="date"
                withoutTheme
                onChange={handleStartDateChange}
                value={startDate ? moment(startDate).format("YYYY-MM-DD") : ""}
              />
            </DateContainer>
            <DateCaption>{formatMessage("transactionFilter.timeRange.to")}</DateCaption>
            <DateContainer>
              <StyledDateInput
                mode="date"
                withoutTheme
                onChange={handleEndDateChange}
                value={endDate ? moment(endDate).format("YYYY-MM-DD") : ""}
              />
            </DateContainer>
            <CheckContainer>
              {isLoading ? (
                <Spinner />
              ) : (
                <RoundActionButton size={27} onClick={handleDownloadAccountStatement} disabled={!isDateChanged}>
                  <SmallCheckIcon />
                </RoundActionButton>
              )}
            </CheckContainer>
          </Row>
        </PanelFilterByDate>
      </GenerateAccountStatementContent>
    </GenerateAccountStatement>
  );
};

const GenerateButtonContainer = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 10px;
  :hover {
    cursor: pointer;
  }
`;
const GenerateAccountStatementContent = styled.div<{ $isVisible: boolean }>`
  ${shadows.medium};
  display: ${(props) => (props.$isVisible ? "block" : "none")};
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  padding: 50px 0 15px 15px;
  margin: -35px 0 0;
`;
const PanelFilterByDate = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const DateTitle = styled.div`
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: bold;
`;
const StyledGenerateButton = styled.div<{ active?: boolean }>`
  ${theme.boldText};
  ${(props) => (!props.active ? shadows.medium : "")};
  display: flex;
  align-items: center;
  background: ${theme.colors.gray[50]};
  font-weight: bold;
  font-size: 15px;
  padding: 6px 12px;
  border-radius: 10px;
`;
const StyledGenerateLabel = styled.span`
  padding-left: 5px;
`;
const DateContainer = styled.div`
  height: 25px;
  margin-right: 8px;
`;
const DateCaption = styled.div`
  font-size: 12px;
  margin-right: 3px;
`;
const StyledDateInput = styled(DateInput)`
  min-width: 110px;
  background-color: ${theme.colors.gray[100]};
  height: 25px;
  padding: 0 3px;
  font-size: 12px;
`;
const CheckContainer = styled(Row)`
  align-items: center;
  justify-content: center;
`;
const SmallCheckIcon = styled(CheckIcon)`
  width: 14px;
  height: 14px;
`;
