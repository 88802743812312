import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { configurationManager } from "../../../../../shared/core/service/services";
import { isDefined } from "../../../../../shared/utils/assert";
import { buildLinkMailTo, buildLinkPhoneTo } from "../../../../../shared/utils/link-builder";
import { useObservable } from "../../../../../shared/utils/observable";
import { Path } from "../../../../core/routing/path";
import { useQueryParam } from "../../../../domain/authentication/use-query-param";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { ResponsiveContainer } from "../../../common/responsive-container";
import { ContactEmailIcon } from "../../../common/svg/contact-email-icon";
import { ContactPhoneIcon } from "../../../common/svg/contact-phone-icon";
import { PhoneFailImage } from "../../../common/svg/phone-fail-image";
import { PhoneSuccessImage } from "../../../common/svg/phone-success-image";
import { StaticAssets } from "../../../styles/custom-assets";
import { theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";

const STATUS_QUERY_PARAM = "status";
const UBBLE_ID_PARAMS = "ubble_identification_id";

export function UbbleCheckScreen() {
  const status = useQueryParam(STATUS_QUERY_PARAM);
  const ubbleId = useQueryParam(UBBLE_ID_PARAMS);

  const { formatMessage } = useIntl();
  const history = useHistory();
  const configuration = useObservable(configurationManager.configuration);
  const { contactEmail, contactPhone } = configuration;

  const showSuccess = useMemo(
    () => status && ubbleId && (status === "processing" || status === "processed"),
    [status, ubbleId],
  );

  const title = useMemo(
    () => formatMessage(showSuccess ? "ubbleCheckScreen.success.title" : "ubbleCheckScreen.fail.title"),
    [formatMessage, showSuccess],
  );

  const subtitle = useMemo(
    () => formatMessage(showSuccess ? "ubbleCheckScreen.success.subtitle" : "ubbleCheckScreen.fail.subtitle"),
    [formatMessage, showSuccess],
  );

  const message = useMemo(
    () => formatMessage(showSuccess ? "ubbleCheckScreen.success.message" : "ubbleCheckScreen.fail.message"),
    [formatMessage, showSuccess],
  );

  const goToRoot = () => {
    history.replace(Path.Root);
  };

  return (
    <Page>
      <Background>
        <BackgroundImage />
        <MainContainer>
          <Logo src={StaticAssets.logo} />
          {showSuccess ? <PhoneSuccess /> : <PhoneFail />}
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Message>{message}</Message>
          <Footer>
            {showSuccess ? (
              <SuccessButton size={"M"} onClick={goToRoot}>
                {formatMessage("ubbleCheckScreen.success.button")}
              </SuccessButton>
            ) : (
              <>
                {isDefined(contactPhone) && (
                  <Button type="phone" href={buildLinkPhoneTo(contactPhone)}>
                    <BackgroundIcon>
                      <ContactPhoneIcon />
                    </BackgroundIcon>
                    <ButtonLegend>{formatMessage("ubbleCheckScreen.fail.byPhone")}</ButtonLegend>
                  </Button>
                )}
                {isDefined(contactEmail) && (
                  <Button type="email" href={buildLinkMailTo(contactEmail)}>
                    <BackgroundIcon>
                      <ContactEmailIcon />
                    </BackgroundIcon>
                    <ButtonLegend>{formatMessage("ubbleCheckScreen.fail.byEmail")}</ButtonLegend>
                  </Button>
                )}
              </>
            )}
          </Footer>
        </MainContainer>
      </Background>
    </Page>
  );
}

const Page = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  overflow: hidden;
  padding: 0;
  margin: 0;
  flex: 1;
  align-items: stretch;
  justify-content: center;
  width: 100%;
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const BackgroundSrc = require("../../../../assets/images/svg/register-background.svg");

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  mask: url(${BackgroundSrc});
  mask-size: cover;
  background-color: ${theme.mainColor};
  width: 100%;
  height: 388px;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    height: 343px;
  }

  @media (min-width: ${UIConstants.DESKTOP_EXTRA_LARGE_BREAKPOINT}px) {
    height: 564px;
  }

  z-index: -100;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: stretch;
  max-width: 536px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (min-width: ${UIConstants.DESKTOP_EXTRA_LARGE_BREAKPOINT}px) {
    max-width: 768px;
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const Logo = styled.img`
  width: 104px;
  height: 104px;
  object-fit: contain;
  border-radius: 13px;
  margin-top: 64px;
  margin-bottom: 70px;
  z-index: 2;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-top: 34px;
    margin-bottom: 74px;
  }
  @media (min-width: ${UIConstants.DESKTOP_EXTRA_LARGE_BREAKPOINT}px) {
    margin-top: 84px;
    margin-bottom: 124px;
    width: 138px;
    height: 138px;
  }
`;

const PhoneSuccess = styled(PhoneSuccessImage)`
  width: 84px;
  height: 147px;
  @media (min-width: ${UIConstants.DESKTOP_EXTRA_LARGE_BREAKPOINT}px) {
    width: 116px;
    height: 203px;
  }
`;

const PhoneFail = styled(PhoneFailImage)`
  width: 84px;
  height: 147px;
  @media (min-width: ${UIConstants.DESKTOP_EXTRA_LARGE_BREAKPOINT}px) {
    width: 116px;
    height: 203px;
  }
`;

const Title = styled.span`
  ${theme.mediumText}
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 1.82px;
  color: #b1b1b1;
  margin-top: 36px;
  margin-bottom: 20px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-top: 50px;
    margin-bottom: 0px;
  }
  @media (min-width: ${UIConstants.DESKTOP_EXTRA_LARGE_BREAKPOINT}px) {
    margin-top: 65px;
    margin-bottom: 20px;
  }
`;

const Subtitle = styled.span`
  ${theme.boldText}
  font-size: 1.25rem;
  color: black;
  text-align: center;
  margin-bottom: 24px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-top: 20px;
    font-size: 1.5rem;
  }
`;

const Message = styled.span`
  ${theme.text}
  font-size: 1.1875rem;
  text-align: center;
  line-height: 1.625rem;
  color: #b1b1b1;
  margin-bottom: 44px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    font-size: 1rem;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
  gap: 52px;
  margin-bottom: 20px;
`;

const SuccessButton = styled(PrimaryButton)`
  margin-top: 0px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-top: 12px;
  }
`;

const BackgroundIcon = styled.div`
  display: flex;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  background-color: #ececec;
  align-items: center;
  justify-content: center;
  margin: 9px;
`;

const Button = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonLegend = styled.span`
  ${theme.mediumText}
  font-size: 0.9375rem;
  color: black;
  text-decoration: underline;
`;
