import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { CARD_REFABRICATION_MODAL_ID } from "../../../../../core/modal/modal-id";
import { Modal } from "../../../../common/modal/modal";
import { SmallChevronIcon } from "../../../../common/svg/small-chevron-icon";
import { theme } from "../../../../styles/theme";
import { CardRefabricateModal } from "../card-refabricate-modal";
import { ActivationCardProps } from "./activation-modal";

export const OrderNewCardButton: React.FC<ActivationCardProps> = ({ card }) => {
  const { formatMessage } = useIntl();

  const handleModalPresent = () => {
    Modal.present(CARD_REFABRICATION_MODAL_ID, () => <CardRefabricateModal card={card} />, {
      canBeDismissed: false,
    });
  };

  return (
    <NewCardContainer
      onClick={() => {
        handleModalPresent();
      }}
    >
      <NewCardLabel>{formatMessage("cardsList.orderCard")}</NewCardLabel>
      <SmallChevronIcon />
    </NewCardContainer>
  );
};

const NewCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const NewCardLabel = styled.div`
  ${theme.boldText}
  color: black;
  font-size: 0.9375rem;
`;
