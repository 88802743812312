import { PhoneNumber } from "libphonenumber-js";
import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { authExecutor } from "../../../../../../shared/core/service/services";
import { formatPhoneNumberBasedOnCountryCode } from "../../../../../../shared/utils/phone-number";
import { PrimaryButton } from "../../../../common/buttons/primary-button";
import { MarkdownText } from "../../../../common/forms/markdown-text";
import { theme } from "../../../../styles/theme";

const SuccessLogo = require("../../../../../assets/images/svg/big-success.svg");

export const OnboardingInitialFormSuccess: React.FC<{
  phoneNumber?: PhoneNumber;
}> = (props) => {
  const { phoneNumber } = props;
  const { formatMessage } = useIntl();
  const formattedPhoneNumber = formatPhoneNumberBasedOnCountryCode(phoneNumber?.nationalNumber, phoneNumber?.country);

  return (
    <Container>
      <SuccessLogoImage src={SuccessLogo} alt="" />
      <Title>{formatMessage("onboardingScreen.successIntroduction")}</Title>
      <Subtitle>{formatMessage("onboardingScreen.initiationSuccessTitle")}</Subtitle>
      <Description>
        {formatMessage("onboardingScreen.initiationSuccessMsg", { phone: formattedPhoneNumber })}
      </Description>
      <PrimaryButton size="S" onClick={() => authExecutor.connect(phoneNumber)}>
        {formatMessage("onboardingScreen.getStartedButton")}
      </PrimaryButton>
    </Container>
  );
};

const SuccessLogoImage = styled.img`
  align-self: center;
  width: 200px;
  height: 200px;
`;

const Container = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(MarkdownText)`
  ${theme.boldText};
  font-size: 2.5rem;
  line-height: 2.875rem;
  margin-bottom: 10px;
`;

const Subtitle = styled(MarkdownText)`
  color: ${theme.colors.gray[700]};
  line-height: 1.875rem;
  align-self: flex-start;
`;
const Description = styled(MarkdownText)`
  color: ${theme.colors.gray[700]};
  line-height: 1.875rem;
  align-self: flex-start;
  margin-bottom: 24px;
`;
