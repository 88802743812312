import React, { SVGProps } from "react";

export const AddWithFilledCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="41" height="40" viewBox="0 0 41 40" {...props}>
    <defs>
      <filter id="x32e6l3nqa">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g filter="url(#x32e6l3nqa)" transform="translate(-155 -172) translate(30 133)">
          <g>
            <path d="M0 0H40V40H0z" transform="translate(125.5 39)" />
            <path
              fill={props.color ?? "#000"}
              d="M20 3.333c9.205 0 16.667 7.462 16.667 16.667 0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.667-7.462-16.667-16.667 0-9.205 7.462-16.667 16.667-16.667zM20.667 10h-1.334c-.552 0-1 .448-1 1v7.333H11c-.552 0-1 .448-1 1v1.334c0 .552.448 1 1 1l7.333-.001V29c0 .552.448 1 1 1h1.334c.552 0 1-.448 1-1l-.001-7.334H29c.552 0 1-.447 1-1v-1.333c0-.552-.448-1-1-1h-7.334V11c0-.552-.447-1-1-1z"
              transform="translate(125.5 39)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
