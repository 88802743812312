import React, { SVGProps } from "react";

export const AddCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width={props.width || 50}
    height={props.height || 50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="50" height="50" rx="25" fill={props.fill || "#B1B1B1"} />
    <mask id="path-2-outside-1_18_1907" maskUnits="userSpaceOnUse" x="15" y="15" width="20" height="20">
      <rect fill="white" x="15" y="15" width="20" height="20" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9997 16C24.8284 16 24.6897 16.1388 24.6897 16.31V24.6897H16.31C16.1388 24.6897 16 24.8284 16 24.9997C16 25.1709 16.1388 25.3097 16.31 25.3097H24.6897V33.69C24.6897 33.8612 24.8284 34 24.9997 34C25.1709 34 25.3097 33.8612 25.3097 33.69V25.3097H33.69C33.8612 25.3097 34 25.1709 34 24.9997C34 24.8284 33.8612 24.6897 33.69 24.6897H25.3097V16.31C25.3097 16.1388 25.1709 16 24.9997 16Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.9997 16C24.8284 16 24.6897 16.1388 24.6897 16.31V24.6897H16.31C16.1388 24.6897 16 24.8284 16 24.9997C16 25.1709 16.1388 25.3097 16.31 25.3097H24.6897V33.69C24.6897 33.8612 24.8284 34 24.9997 34C25.1709 34 25.3097 33.8612 25.3097 33.69V25.3097H33.69C33.8612 25.3097 34 25.1709 34 24.9997C34 24.8284 33.8612 24.6897 33.69 24.6897H25.3097V16.31C25.3097 16.1388 25.1709 16 24.9997 16Z"
      fill="#F4F4F4"
    />
    <path
      d="M24.6897 24.6897V25.6897H25.6897V24.6897H24.6897ZM24.6897 25.3097H25.6897V24.3097H24.6897V25.3097ZM25.3097 25.3097V24.3097H24.3097V25.3097H25.3097ZM25.3097 24.6897H24.3097V25.6897H25.3097V24.6897ZM25.6897 16.31C25.6897 16.6911 25.3807 17 24.9997 17V15C24.2762 15 23.6897 15.5865 23.6897 16.31H25.6897ZM25.6897 24.6897V16.31H23.6897V24.6897H25.6897ZM16.31 25.6897H24.6897V23.6897H16.31V25.6897ZM17 24.9997C17 25.3807 16.6911 25.6897 16.31 25.6897V23.6897C15.5865 23.6897 15 24.2762 15 24.9997H17ZM16.31 24.3097C16.6911 24.3097 17 24.6186 17 24.9997H15C15 25.7231 15.5865 26.3097 16.31 26.3097V24.3097ZM24.6897 24.3097H16.31V26.3097H24.6897V24.3097ZM25.6897 33.69V25.3097H23.6897V33.69H25.6897ZM24.9997 33C25.3807 33 25.6897 33.3089 25.6897 33.69H23.6897C23.6897 34.4135 24.2762 35 24.9997 35V33ZM24.3097 33.69C24.3097 33.3089 24.6186 33 24.9997 33V35C25.7231 35 26.3097 34.4135 26.3097 33.69H24.3097ZM24.3097 25.3097V33.69H26.3097V25.3097H24.3097ZM33.69 24.3097H25.3097V26.3097H33.69V24.3097ZM33 24.9997C33 24.6186 33.3089 24.3097 33.69 24.3097V26.3097C34.4135 26.3097 35 25.7231 35 24.9997H33ZM33.69 25.6897C33.3089 25.6897 33 25.3807 33 24.9997H35C35 24.2762 34.4135 23.6897 33.69 23.6897V25.6897ZM25.3097 25.6897H33.69V23.6897H25.3097V25.6897ZM24.3097 16.31V24.6897H26.3097V16.31H24.3097ZM24.9997 17C24.6186 17 24.3097 16.6911 24.3097 16.31H26.3097C26.3097 15.5865 25.7231 15 24.9997 15V17Z"
      fill="#F4F4F4"
      mask="url(#path-2-outside-1_18_1907)"
    />
  </svg>
);
