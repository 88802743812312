import React from "react";
import styled from "styled-components";
import { FormIntInput } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { TextInput } from "../forms/text-input";
import { DisableValueText } from "./disable-value-text";

interface IntFieldProps {
  input: FormIntInput;
  hasError?: boolean;
  onChange?: (value: number) => void;
  disabled?: boolean;
}

export const IntField: React.FC<IntFieldProps> = ({ input, disabled, hasError, onChange }) => {
  const { formatMessage } = useIntl();

  return input.disabled || disabled ? (
    <DisableValueText>{input.value}</DisableValueText>
  ) : (
    <StyledTextInput
      value={input.value?.toString() ?? ""}
      required={input.required}
      type="text"
      onChange={(e) => {
        if (onChange) {
          const parsedInt = parseInt(e.target.value);
          onChange(isNaN(parsedInt) ? 0 : parsedInt);
        }
      }}
      placeholder={formatMessage("billFormScreen.textInputPlaceholder")}
      hasError={hasError ? hasError : null}
    />
  );
};

const StyledTextInput = styled(TextInput)`
  input::placeholder {
    color: #b1b1b1;
  }
`;
