import useComponentSize from "@rehooks/component-size";
import React, { HTMLAttributes, useEffect, useMemo, useRef } from "react";
import styled, { css } from "styled-components";
import { colorWithOpacity } from "../../../../shared/utils/colors";
import { repeat } from "../../../../shared/utils/utils";
import { useRTL } from "../../../domain/language/use-rtl";
import { UIConstants } from "../../styles/uiConstants";
import { ArrowButton } from "./arrow-buttons";
import { Direction, oppositeDirection } from "./direction";
import { useCarouselAnimations } from "./use-carousel-animations";

interface CarouselProps<T> extends HTMLAttributes<HTMLDivElement> {
  items: T[];
  itemWidth: number;
  loading?: boolean;
  renderItem: (item: T, index?: number) => React.ReactNode;
  renderSkeleton?: (index?: number) => React.ReactNode;
  skeletonNumber?: number;
  horizontalPadding?: number;
  spacingBetweenItems?: number;
  slideNumber?: number;
  faderWidth?: number;
  innerRef?: React.RefObject<HTMLDivElement>;
  theme?: "ArrowOnTop" | "BottomIndicator";
  onUpdateCurrentImage?: (index: number) => void;
}
export type CarouselType<K> = React.FC<CarouselProps<K>>;

export function Carousel<T>(props: CarouselProps<T>) {
  const {
    innerRef,
    items,
    itemWidth,
    loading,
    skeletonNumber = 3,
    horizontalPadding: contentHorizontalPadding = 70,
    spacingBetweenItems = 0,
    slideNumber = 1,
    faderWidth = 0,
    theme = "ArrowOnTop",
    renderItem,
    renderSkeleton,
    onUpdateCurrentImage,
    ...rest
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const { width: containerWidth } = useComponentSize(innerRef ?? containerRef);
  const carouselWidth = useMemo(
    () => containerWidth + 2 * contentHorizontalPadding,
    [containerWidth, contentHorizontalPadding],
  );
  const effectiveItemWidth = useMemo(() => itemWidth + spacingBetweenItems, [itemWidth, spacingBetweenItems]);

  const maxVisibleElements = useMemo(
    () => Math.floor((carouselWidth - contentHorizontalPadding) / effectiveItemWidth),
    [carouselWidth, contentHorizontalPadding, effectiveItemWidth],
  );
  const allItemsDisplayed = useMemo(() => maxVisibleElements >= items.length, [maxVisibleElements, items.length]);
  const { isRTL } = useRTL();

  const {
    scrollingDiv,
    handleScroll,
    animateScroll,
    snapOrStopScrolling,
    isDisabled,
    scrollToElementIndex,
    firstElementVisible: activeElementIndex,
  } = useCarouselAnimations(effectiveItemWidth, slideNumber, allItemsDisplayed, isRTL);

  useEffect(() => {
    onUpdateCurrentImage?.(activeElementIndex);
  }, [activeElementIndex, onUpdateCurrentImage]);

  const directionsArray: Direction[] = useMemo(() => (isRTL ? ["right", "left"] : ["left", "right"]), [isRTL]);

  return (
    <Container ref={innerRef ?? containerRef} {...rest}>
      {theme === "ArrowOnTop" && (
        <ArrowsContainer $isRTL={isRTL}>
          {directionsArray.map((dir) => (
            <ArrowButton
              key={dir}
              direction={dir}
              disabled={isDisabled(dir)}
              onPressStart={() => animateScroll(isRTL ? oppositeDirection(dir) : dir)}
              onPressEnd={(canceled) => snapOrStopScrolling(isRTL ? oppositeDirection(dir) : dir, canceled)}
            />
          ))}
        </ArrowsContainer>
      )}
      <RelativeContainer width={carouselWidth} contentHorizontalPadding={contentHorizontalPadding}>
        {!!faderWidth &&
          directionsArray.map((dir) => (
            <Fader key={dir} direction={dir} invisible={isDisabled(dir)} size={faderWidth} />
          ))}
        <Row
          ref={scrollingDiv}
          onScroll={handleScroll}
          contentHorizontalPadding={contentHorizontalPadding}
          $isRTL={isRTL}
        >
          <Content contentHorizontalPadding={contentHorizontalPadding} spacing={spacingBetweenItems} $isRTL={isRTL}>
            {loading && renderSkeleton
              ? repeat(skeletonNumber, (index) => renderSkeleton(index))
              : items.map((item, index) => renderItem(item, index))}
          </Content>
        </Row>
        {theme === "BottomIndicator" && items.length > 0 && (
          <LineWrapper>
            {items.map((_, index) => (
              <Line key={index} isActive={index === activeElementIndex} onClick={() => scrollToElementIndex(index)} />
            ))}
          </LineWrapper>
        )}
      </RelativeContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

const ArrowsContainer = styled.div<{ $isRTL: boolean }>`
  ${(props) => (props.$isRTL ? "margin-right: auto" : "margin-left: auto")};
  display: flex;
  margin-top: -42px;
  div:first-child {
    ${(props) => (props.$isRTL ? "margin-left: 13px" : "margin-right: 13px")};
  }
  @media (max-width: ${UIConstants.PHONE_BREAKPOINT - 1}px) {
    ${(props) => (props.$isRTL ? "margin-right: 0" : "margin-left: 0")};
    margin-top: 4px;
    justify-content: flex-start;
  }
`;

const RelativeContainer = styled.div<{ width: number; contentHorizontalPadding: number }>`
  position: relative;
  width: ${(props) => props.width};
  // margin: 0 ${(props) => `${props.contentHorizontalPadding}px`};
`;

export const Row = styled.div<{ contentHorizontalPadding: number; $isRTL: boolean }>`
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100%;
  padding-left: ${(props) => (props.$isRTL ? 0 : props.contentHorizontalPadding)}px;
  padding-right: ${(props) => (props.$isRTL ? props.contentHorizontalPadding : 0)}px;

  padding-top: 30px;
  padding-bottom: 30px;
`;

const Content = styled.div<{ contentHorizontalPadding: number; spacing: number; $isRTL: boolean }>`
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding-left: ${(props) => (props.$isRTL ? props.contentHorizontalPadding : 0)}px;
  padding-right: ${(props) => (props.$isRTL ? 0 : props.contentHorizontalPadding)}px;

  > *:not(:last-child) {
    flex: 0 0 auto;
    margin-right: ${(props) => (props.$isRTL ? 0 : props.spacing)}px;
    margin-left: ${(props) => (props.$isRTL ? props.spacing : 0)}px;
  }
`;

const Fader = styled.div<{ invisible: boolean; direction: Direction; size: number }>`
  position: absolute;
  display: block;

  top: 0;
  bottom: 0;
  z-index: 20;
  transition: opacity 0.1s linear;
  opacity: ${(props) => (props.invisible ? 0 : 1)};
  width: ${(props) => props.size}px;

  pointer-events: none;
  > * {
    pointer-events: ${(props) => (props.invisible ? "none" : "all")};
  }

  ${({ direction }) => css`
		background: linear-gradient(to ${oppositeDirection(direction)}, ${"#f4f4f4"} 0%, ${"#f4f4f4"} 20%, ${colorWithOpacity(
      "#f4f4f4",
      0.8,
    )} 50%,${colorWithOpacity("#f4f4f4", 0.5)} 80%,  ${"#f4f4f4"}00 100%)};
	`}

  ${({ direction }) =>
    direction === "left"
      ? css`
          left: 0;
          justify-content: flex-start;
        `
      : css`
          right: 0;
          justify-content: flex-end;
        `}
`;

const LineWrapper = styled.div`
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 5px;
  }

  > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const Line = styled.div<{ isActive: boolean }>`
  position: relative;
  width: 45px;
  height: 35px;
  cursor: pointer;

  :after {
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    height: 5px;
    border-radius: 4px;
    opacity: ${(props) => (props.isActive ? 1 : 0.3)};
    background-color: #000;
    transition: opacity linear 0.2s;
  }
`;
