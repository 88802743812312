import copy from "copy-to-clipboard";
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { TransactionCode } from "../../../../../shared/domains/transactions-codes/transaction-code";
import { useCountdown } from "../../../../../shared/utils/countdown";
import { getRandomID } from "../../../../../shared/utils/random";
import { useTooltip } from "../../../../utils/use-tooltip";
import { AmountText } from "../../../common/amount-text";
import { RoundedSquareButton } from "../../../common/shape/rounded-square";
import { CopyToClipboardIcon } from "../../../common/svg/copy-to-clipboard-icon";
import { ExpirationIcon } from "../../../common/svg/expiration-icon";
import { shadows, theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";

export interface TransactionCodeCardProps {
  transactionCode: TransactionCode;
}

export const TransactionCodeCard = (props: TransactionCodeCardProps) => {
  const { transactionCode } = props;

  const countdown = useCountdown(transactionCode.date);
  const isTransactionExpired = new Date(transactionCode.date) <= new Date();

  const { elementRef, showTooltip } = useTooltip<HTMLButtonElement>();
  const { formatMessage } = useIntl();
  const [uid] = useState(getRandomID());
  const copyToClipboard = () => {
    copy(transactionCode.code);
    showTooltip();
  };

  return isTransactionExpired ? null : (
    <Card>
      <TopRow>
        <TransactionCodeDetailContainer>
          <Code>{transactionCode.code}</Code>
          <AcceptorName>{transactionCode.acceptor?.name}</AcceptorName>
        </TransactionCodeDetailContainer>
        <CopyCode
          data-for={uid}
          ref={elementRef}
          size={36}
          data-tip={formatMessage("accountDetails.clipboard", { name: formatMessage("transactionCode.codeTooltip") })}
          onClick={copyToClipboard}
        >
          <CopyToClipboardIcon />
        </CopyCode>
        <ReactTooltip id={uid} place="top" type="dark" effect="solid" event={"none"} />
      </TopRow>

      <BottomRow>
        <Amount amount={transactionCode.amount} />
        <ExpirationContainer>
          <StyledExpirationIcon />
          <ExpirationValueContainer>
            <HiddenExpirationValue>{"00:00:00"}</HiddenExpirationValue>
            <ExpirationValue>{countdown}</ExpirationValue>
          </ExpirationValueContainer>
        </ExpirationContainer>
      </BottomRow>
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${UIConstants.ACCOUNT_TILE_WIDTH_NOT_MOBILE}px;
  min-height: 188px;
  padding: 30px;
  border-radius: 13px;
  ${shadows.medium};
  background-color: #ffffff;
  color: #000000;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    width: ${UIConstants.ACCOUNT_TILE_WIDTH_MOBILE}px;
    padding: 15px;
    min-height: 158px;
  }
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    width: ${UIConstants.ACCOUNT_TILE_WIDTH_MOBILE}px;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
`;

const TransactionCodeDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Code = styled.span`
  font-size: 1.125rem;
  ${theme.mediumText};
  color: #000000;
`;

const AcceptorName = styled.span`
  ${theme.text};
  font-size: 1.125rem;
  color: #b1b1b1;
`;

const CopyCode = styled(RoundedSquareButton)`
  background-color: #f4f4f4;
  border-radius: 12px;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Amount = styled(AmountText)`
  font-size: 1.125rem;
  ${theme.boldText};
  color: #000000;
`;

const ExpirationContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  background-color: #ececec;
  height: 28px;
  border-radius: 25px;
  padding: 4px 12px;
`;

const ExpirationValueContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const StyledExpirationIcon = styled(ExpirationIcon)`
  margin-right: 4px;
`;

const ExpirationValue = styled.span`
  position: absolute;
  font-size: 1.125rem;
  ${theme.mediumText};
  color: #000000;
  left: 0;
`;

const HiddenExpirationValue = styled.div`
  font-size: 1.125rem;
  visibility: hidden;
  left: 0;
`;
