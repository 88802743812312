import React from "react";
import { useHistory } from "react-router-dom";

import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { Card } from "../../../../../../shared/domains/cards/card";
import { Path } from "../../../../../core/routing/path";
import { ActionMenu } from "../action-menu";

interface OutstandingsButtonProps {
  card: Card;
  className?: string;
}

export const OutstandingsButton: React.FC<OutstandingsButtonProps> = ({ card, className }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <ActionMenu
      title={formatMessage("cardOptions.outstandings.navigateButton")}
      className={className}
      onClick={() => {
        history.push(`${Path.CardOutstandings}?card_id=${card.id}`);
      }}
    />
  );
};
