import React, { HTMLAttributes, useState } from "react";
import styled from "styled-components";

import { billIssuerManager } from "../../../../../shared/core/service/services";
import { BillCategory, BillIssuer } from "../../../../../shared/domains/bills/bill";
import { BILL_ISSUER_MODAL_ID } from "../../../../core/modal/modal-id";
import { DeleteButton, MoreButton } from "../../../common/card-more-menu-actions";
import { ImageWithFallback } from "../../../common/imageWithFallback";
import { Modal } from "../../../common/modal/modal";
import { theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";
import { performTaskIfPossible } from "../../pincode/assert-banking-task";
import { BillIssuerModal } from "../bill-issuer-modal";
import { billCategoryIcon } from "./bill-category-icons";

interface BillIssuerViewProps extends HTMLAttributes<HTMLDivElement> {
  issuer: BillIssuer;
  topLevelCategory?: BillCategory;
  color: string;
  withReference?: boolean;
}

export const BillIssuerView: React.FC<BillIssuerViewProps> = ({
  issuer,
  topLevelCategory,
  color,
  withReference,
  ...otherProps
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => setOpenMenu((bool) => !bool);

  return (
    <IssuerContainer
      {...otherProps}
      onClick={performTaskIfPossible(() => {
        Modal.present(
          BILL_ISSUER_MODAL_ID,
          () => <BillIssuerModal issuer={issuer} topLevelCategory={topLevelCategory} color={color} />,
          {
            canBeDismissed: false,
          },
        );
      })}
    >
      <IssuerIcon>
        <LogoWithFallback
          src={issuer.logoUrl || ""}
          width={LOGO_WIDTH}
          height={LOGO_HEIGHT}
          renderErrorImage={() => (
            <Logo
              style={{ backgroundColor: color }}
              src={billCategoryIcon[topLevelCategory?.id ?? ""] || billCategoryIcon.default}
            />
          )}
        />
      </IssuerIcon>
      <DataContainer>
        {issuer.name && <IssuerName>{issuer.name}</IssuerName>}
        {withReference && issuer.references?.[0] && <IssuerReference>{issuer.references[0]}</IssuerReference>}
      </DataContainer>
      {withReference && (
        <MoreMenu>
          <MoreButton active={openMenu} onClick={toggleMenu} />
          <DeleteButton show={openMenu} onClick={() => billIssuerManager.deleteReference(issuer)} />
        </MoreMenu>
      )}
    </IssuerContainer>
  );
};

const IssuerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

const IssuerIcon = styled.div`
  width: 65px;
  height: 45px;
  border-radius: 9px;
  margin: 16px;
  align-items: center;
  justify-content: center;
`;
const IssuerName = styled.span`
  ${theme.mediumText}
  font-size: 0.9375rem;
  color: #000000;
  flex-shrink: 1;
  padding-right: 10px;
`;

const LOGO_WIDTH = 55;
const LOGO_HEIGHT = 45;

const LogoWithFallback = styled(ImageWithFallback)`
  object-fit: contain;
`;

const Logo = styled.img`
  width: ${LOGO_WIDTH}px;
  height: ${LOGO_HEIGHT}px;
  padding: 10px;
  object-fit: contain;
  border-radius: 10px;
`;

const MoreMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  right: 25px;
  top: 0px;
  bottom: 0px;
  z-index: 1;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const IssuerReference = styled.span`
  ${theme.text}
  font-size: 0.9375rem;
  color: #000000;
`;
