import React, { useState } from "react";
import { MultiSelectInput, MultiSelectInputType } from "../forms/multi-select-input";

import styled from "styled-components";
import { FormMultiSelectInput } from "../../../../shared/core/data-forms/form-input-types";
import { isAnNotEmptyArray } from "../../../../shared/utils/utils";
import { DisableValueText } from "./disable-value-text";

export const MultiSelectField: React.FC<{
  input: FormMultiSelectInput;
  onChange: (value: string[]) => void;
  disabled?: boolean;
}> = ({ input, onChange, disabled }) => {
  const getInitialSelectedValues = () => {
    if (!input.value || !isAnNotEmptyArray(input.value)) {
      return [];
    }

    return input.value.map((i) => input.values.find((e) => e.id === i)).filter((item) => item !== undefined);
  };

  const [selected, setSelected] = useState(getInitialSelectedValues());

  return input.disabled || disabled ? (
    <DisableValueText>{getSelectedOptionsLabels(input)}</DisableValueText>
  ) : (
    <InputContainer>
      <MultiSelect
        innerId={input.id}
        options={input.values}
        selectedValues={selected}
        disabled={input.disabled}
        required={input.required}
        onChange={(selectedItems) => {
          setSelected(selectedItems);
          if (onChange) {
            onChange(selectedItems.length !== 0 ? selectedItems.map((v) => v.id.trim()) : []);
          }
        }}
        itemRenderer={(v) => v?.label ?? ""}
      />
    </InputContainer>
  );
};

const MultiSelect = styled<MultiSelectInputType<{ id: string; label: string }>>(MultiSelectInput)`
  width: 100%;

  .multi-select {
    background-color: transparent;
    > div {
      min-height: 48px;
      border-radius: 10px;
      border: 0;
      div[class$="-placeholder"] {
        position: relative;
        top: 10px;
      }
      div[class$="-multiValue"] + div {
        padding: 0;
        margin: 1px;
      }

      input[type="text"] {
        height: 0;
        padding: 0;
        margin: 0;
      }
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  padding-bottom: 10px;
`;

function getSelectedOptionsLabels(input: FormMultiSelectInput) {
  let selectedLabels = "";

  if (input.value) {
    input.value.forEach((id) => {
      const value = input.values?.find((e) => e.id === id);
      if (value) {
        selectedLabels = selectedLabels.concat(" " + value.label);
      }
    });
  }

  return selectedLabels;
}
