import { FormCollectionInput } from "../core/data-forms/form-input-types.tsx";

export function formatCollectionInputs(inputs: FormCollectionInput[]) {
  /**
   * Adds the 'inputs' field to every collection which does not have it, and
   * removes collections that have no inputs or where 'inputs' is an empty array.
   */
  return inputs
    .map((collection) => ({ ...collection, inputs: collection.inputs || [] }))
    .filter((collection) => collection.inputs.length > 0);
}
