import { find } from "lodash";
import { useMemo } from "react";
import { Amount, amountValueForCurrency } from "../../core/amount/amount";
import { currencyManager, featuresManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";
import { useAsyncEffect } from "../../utils/utils";

export default function useCurrencies(amount?: Amount | null, conversionEnabled?: boolean) {
  const exchangeRates = useObservable(currencyManager.exchangeRates);
  const features = useObservable(featuresManager.features);
  const canShowExchangeRate = features.sdaExchangeRateView && !features.customerInstructionInitiation;

  const matchingExchangeRate = useMemo(() => {
    if (exchangeRates && amount && conversionEnabled) {
      return find(exchangeRates, (rate) => rate.baseCurrency === amount.currency && rate.foreignCurrency === "EUR");
    }
    return null;
  }, [exchangeRates, amount, conversionEnabled]);

  useAsyncEffect(async () => {
    if (canShowExchangeRate && !exchangeRates && conversionEnabled) {
      await currencyManager.getExchangeRates();
    }
  }, [canShowExchangeRate, exchangeRates]);

  const convertedAmount = useMemo(() => {
    if (amount && matchingExchangeRate) {
      const baseValue = amountValueForCurrency(amount.value, matchingExchangeRate.baseCurrency);
      return new Amount(baseValue * matchingExchangeRate.exchangeRates.sellRate, matchingExchangeRate.foreignCurrency);
    }
    return null;
  }, [amount, matchingExchangeRate]);

  return {
    canShowExchangeRate,
    convertedAmount,
    getExchangeRate: currencyManager.getExchangeRates,
  };
}
