import React, { useRef } from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { billIssuerManager } from "../../../../../shared/core/service/services";
import { BillIssuer } from "../../../../../shared/domains/bills/bill";
import { isAccountBlocked } from "../../../../../shared/domains/pincode/pincode-error";
import { areAllRequiredInputsFilled } from "../../../../../shared/utils/form";
import { useScrollToEnd } from "../../../../utils/use-scroll-to-end";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../common/error-message";
import { ScrollableDiv } from "../../../common/modal/scrollable-div";
import { MainColorSpinner } from "../../../common/spinner";
import { usePaybillFlow } from "../utils/use-paybill-flow";
import { BillFormItem } from "./bill-form-item";

interface BillIssuerFormProps {
  issuer: BillIssuer;
}

export const BillIssuerForm = (props: BillIssuerFormProps) => {
  const { issuer } = props;

  const { formatMessage } = useIntl();

  const saveReference = (issuer: BillIssuer, newReference: { label: string; value: string }) => {
    if (
      window.confirm(
        formatMessage(
          issuer.references ? "billFormScreen.replaceReferencePromptTitle" : "billFormScreen.saveReferencePromptTitle",
          { name: newReference.label },
        ) +
          ". " +
          formatMessage(
            issuer.references
              ? "billFormScreen.replaceReferencePromptMessage"
              : "billFormScreen.saveReferencePromptMessage",
            { name: newReference.label },
          ),
      )
    ) {
      billIssuerManager.saveReference(issuer, newReference.value);
    }
  };

  const { sendInputs, inputs, updateInputValue, latestInputs, loading, error } = usePaybillFlow(issuer, saveReference);

  const lastElementRef = useRef<HTMLDivElement | null>(null);
  useScrollToEnd(lastElementRef, [latestInputs, loading, error]);

  return (
    <Form
      onSubmit={(e) => {
        e?.preventDefault();
        sendInputs(latestInputs);
      }}
    >
      <BillFormContainer childrenClassName="scroll-content">
        {inputs.map((input, index) => (
          <BillFormItem issuer={issuer} key={`${input.id}_${index}`} showLeftDecoration input={input} disabled />
        ))}
        {latestInputs.map((input, index) => (
          <BillFormItem
            issuer={issuer}
            key={`latest_${input.id}_${index}`}
            showLeftDecoration={index < latestInputs.length - 1}
            input={input}
            onChange={(value) => updateInputValue(input, value)}
          />
        ))}
        {loading && <Spinner />}
        {error && !isAccountBlocked(error) && <StyledErrorMessage>{error}</StyledErrorMessage>}
        <Spacer ref={lastElementRef} />
      </BillFormContainer>
      <ButtonContainer>
        <PrimaryButton
          size="M"
          type="submit"
          disabled={!areAllRequiredInputsFilled(latestInputs) || latestInputs.length < 1 || loading}
          style={{ marginTop: 20 }}
        >
          {formatMessage("billFormScreen.nextButton")}
        </PrimaryButton>
      </ButtonContainer>
    </Form>
  );
};

const Spinner = styled(MainColorSpinner)`
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 15px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding: 20px 0 20px 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const Spacer = styled.div`
  flex: 1;
`;
const BillFormContainer = styled(ScrollableDiv)`{
    .scroll-content {
        overflow-x: hidden;
    }
}
`;
