import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { Config } from "../../../shared/core/config/config";
import { useIntl } from "../../../shared/core/i18n/use-intl";
import { useRTL } from "../../domain/language/use-rtl";
import { theme } from "../styles/theme";
import { UIConstants } from "../styles/uiConstants";
import { PrimaryExternalLink } from "./buttons/primary-button";

export interface StoreButtonsProps extends HTMLAttributes<HTMLDivElement> {
  showIsAvailable?: boolean;
  smallVersion?: boolean;
}

export const StoreButtons = (props: StoreButtonsProps) => {
  const { formatMessage } = useIntl();
  const { showIsAvailable, smallVersion } = props;

  const { isRTL } = useRTL();

  return (
    <>
      {Config.APP_APPLE_STORE_ID || Config.APP_ID ? (
        <StoresSiteWrapper>
          {showIsAvailable && (
            <StoreAvailable $isRTL={isRTL}>{formatMessage("authScreen.storesAvailable")}</StoreAvailable>
          )}
          <StoreButtonWrapper $isRTL={isRTL}>
            {Config.APP_APPLE_STORE_ID && (
              <AppstoreLink
                size="S"
                target="_blank"
                href={"itms-apps://itunes.apple.com/app/apple-store/idtest?mt=8"}
                isSmallVersion={smallVersion}
              >
                <AppstoreLogo
                  src={require("../../assets/images/store/appstore_icon.png")}
                  $isRTL={isRTL}
                  isSmallVersion={smallVersion}
                />
                <span>{formatMessage("authScreen.appstoreLink")}</span>
              </AppstoreLink>
            )}
            {Config.APP_ID && (
              <PlaystoreLink
                size="S"
                target="_blank"
                href={`http://play.google.com/store/apps/details?id=${Config.APP_ID}`}
                $isRTL={isRTL}
                isSmallVersion={smallVersion}
              >
                <PlaystoreLogo
                  src={require("../../assets/images/store/playstore_icon.png")}
                  $isRTL={isRTL}
                  isSmallVersion={smallVersion}
                />
                <span>{formatMessage("authScreen.playstoreLink")}</span>
              </PlaystoreLink>
            )}
          </StoreButtonWrapper>
        </StoresSiteWrapper>
      ) : null}
    </>
  );
};

const StoresSiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    align-items: flex-start;
    margin-top: 40px;
  }
`;

const StoreAvailable = styled.span<{ $isRTL: boolean }>`
  ${theme.text};
  text-align: ${(props) => (props.$isRTL ? "left" : "right")};
  margin-bottom: 8px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    self-align: flex-start;
  }
`;

const StoreButtonWrapper = styled.div<{ $isRTL: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  @media (max-width: ${UIConstants.DESKTOP_LARGE_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

const StoreLink = styled(PrimaryExternalLink)<{ isSmallVersion?: boolean }>`
  ${theme.text}
  font-size: ${(props) => (props.isSmallVersion ? 0.75 : 1)}rem;
  padding: ${(props) => (props.isSmallVersion ? "20px 16px" : "13px 20px")};
  height: ${(props) => (props.isSmallVersion ? "24px" : "auto")};
`;

const AppstoreLink = styled(StoreLink)<{ isSmallVersion?: boolean }>``;

const PlaystoreLink = styled(StoreLink)<{ $isRTL: boolean }>`
  background-color: #fff;
  color: #000;

  :focus,
  :active,
  :hover {
    background-color: #f9f9f9;
  }

  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
    margin-left: unset;
    margin-right: unset;
  }
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-top: 0;
  }
`;

const PlaystoreLogo = styled.img<{ $isRTL: boolean; isSmallVersion?: boolean }>`
  width: ${(props) => (props.isSmallVersion ? 16 : 20)}px;
  height: auto;
  ${(props) => (props.$isRTL ? "margin-left: 10px" : "margin-right: 10px")};
`;

const AppstoreLogo = styled.img<{ $isRTL: boolean; isSmallVersion?: boolean }>`
  width: ${(props) => (props.isSmallVersion ? 12 : 15)}px;
  height: auto;
  ${(props) => (props.$isRTL ? "margin-left: 10px" : "margin-right: 10px")};
`;
