import { BillCategory, BillIssuer, BillIssuerType } from "./bill";

import { AnalyticsService } from "../../core/analytics/analytics-service";
import { CacheLoader } from "../../core/cache/cache-loader";
import { CacheStatus } from "../../core/cache/cache-status";
import { DeviceInfoService } from "../../core/device-info-service";
import { logger } from "../../core/logging/logger";
import { Observable } from "../../utils/observable";
import { ObservableTree } from "../../utils/observable-tree";
import { AuthenticationManager } from "../authentication/authentication-manager";
import { ClientManager } from "../client/client-manager";
import { BillService } from "./bill-service";

export class BillIssuerManager {
  public issuers = new ObservableTree<BillIssuer | BillCategory, (BillIssuer | BillCategory)[]>([]);
  public loading = new Observable<boolean>(true);
  public error = new Observable<Error | null>(null);
  public refreshing = new Observable<boolean>(false);
  public cacheStatus = new Observable<CacheStatus | null>(null);

  public constructor(
    private billService: BillService,
    private authenticationManager: AuthenticationManager,
    private clientManager: ClientManager,
    private cacheLoader: CacheLoader<(BillIssuer | BillCategory)[]>,
    private analyticsService: AnalyticsService,
    private deviceInfoService: DeviceInfoService,
  ) {
    this.authenticationManager.isAuthenticated.onChange.add(async (isAuthenticated) => {
      if (!isAuthenticated) {
        await this.clear();
      } else {
        await this.load();
      }
    });
    this.clientManager.onLanguageChange.add(async () => {
      logger.debug("clientManager.onLanguageChange");
      await this.clear();
      await this.load();
    });
  }

  public async load(billItemId?: BillIssuerType) {
    this.loading.set(true);
    logger.debug("load issuers");
    await this.loadOrRefresh(billItemId);
    this.loading.set(false);
  }

  public async refresh(billItemId?: BillIssuerType) {
    this.refreshing.set(true);
    logger.debug("refresh issuers");
    await this.loadOrRefresh(billItemId, true);
    this.refreshing.set(false);
  }

  private async loadOrRefresh(billItemId?: BillIssuerType, forceRefresh?: boolean) {
    logger.debug("loadOrRefresh");
    this.addObservability(forceRefresh);
    try {
      this.error.set(null);
      const issuers = await this.cacheLoader.load(() => this.billService.fetchIssuers(billItemId), forceRefresh);
      if (!issuers) {
        throw new Error("Failed to refresh bill issuers");
      }
      await this.updateCacheStatus();
      this.issuers.set(issuers);
    } catch (e) {
      this.error.set(e);
    }
  }

  public async saveReference(issuer: BillIssuer, newReference: string) {
    const newIssuer = await this.billService.saveReference(issuer, newReference);
    this.issuers.replace(newIssuer, (existingIssuer) => existingIssuer.id === newIssuer.id);
    this.cacheLoader.store(this.issuers.get());
    await this.updateCacheStatus();
  }

  public async deleteReference(issuer: BillIssuer) {
    await this.billService.deleteReference(issuer);
    this.cacheLoader.store(this.issuers.get());
    await this.updateCacheStatus();
    await this.refresh();
  }

  private async clear() {
    logger.debug("clear issuers");
    await this.cacheLoader.clear();
    this.issuers.set([]);
    this.cacheStatus.set(null);
  }

  private async updateCacheStatus() {
    const cacheStatus = await this.cacheLoader.readStatus();
    this.cacheStatus.set(cacheStatus);
  }

  private async addObservability(forceRefresh?: boolean) {
    try {
      const isValid = await this.cacheLoader.isValid();
      if (!isValid) {
        const message = "bill_issuers_cache_is_not_valid";
        logger.debug(message);
        await this.analyticsService.event(message, {
          device_model: this.deviceInfoService.getModel(),
        });
      }

      if (isValid && forceRefresh) {
        const message = "bill_issuers_cache_force_the_refresh";
        logger.debug(message);
        await this.analyticsService.event(message, {
          device_model: this.deviceInfoService.getModel(),
        });
      }
    } catch (error) {
      logger.error("BillIssuerManager: ", error);
    }
  }
}
