import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import { getRandomID } from "../../../../shared/utils/random";
import { repeat } from "../../../../shared/utils/utils";
import { TextInput } from "./text-input";

interface OtpInputProps {
  length: number;
  onOtpChange?: (otp: string) => void;
  className?: string;
}
interface Input {
  id: string;
  name: string;
  value: string;
}
// keyCode constant
const BACKSPACE = 8;
const DELETE = 46;

export interface OtpInputRef {
  reset: () => void;
}
export const OtpInput = forwardRef<OtpInputRef, OtpInputProps>((props, ref) => {
  const { length, onOtpChange, className } = props;
  const [inputs, setInputs] = useState<Input[]>(
    repeat(length, (index) => ({
      id: `otpInput_${index}_${getRandomID()}`,
      name: `otpInput${index}`,
      value: "",
    })),
  );
  const [focusInputId, setFocusInputId] = useState(inputs[0].id);

  const resetOtp = () => {
    setInputs((prevInputs) => prevInputs.map((input) => ({ ...input, value: "" })));
    document.getElementById(inputs[0].id)?.focus({ preventScroll: true });
    onOtpChange?.("");
  };
  useImperativeHandle(ref, () => ({
    reset: resetOtp,
  }));

  useEffect(() => {
    document.getElementById(focusInputId)?.focus({ preventScroll: true });
  }, [focusInputId]);

  const handleRemoveKeys = (e: React.KeyboardEvent<HTMLInputElement>, inputIndex: number) => {
    if (props.disabled) {
      return;
    }

    if (e.keyCode === BACKSPACE || e.key === "Backspace" || e.keyCode === DELETE || e.key === "Delete") {
      e.preventDefault();
      if (inputs[inputIndex].value.length > 0) {
        inputs[inputIndex].value = "";
        setInputs([...inputs]);
      } else if (inputIndex > 0) {
        const previousInput = inputs[inputIndex - 1];
        if (previousInput.value.length > 0) {
          inputs[inputIndex - 1].value = "";
          setInputs([...inputs]);
          setFocusInputId(inputs[inputIndex - 1].id);
        }
      }
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, inputIndex: number) => {
    if (props.disabled) {
      return;
    }

    const { value } = e.target;
    const sanitazedValue = value.replace(/[^0-9]/g, "");
    let nextFocusId = inputs[inputIndex].id;
    if (value.length === 0 && sanitazedValue.length === 0) {
      const prevInputIndex = inputIndex - 1;
      if (prevInputIndex >= 0) {
        nextFocusId = inputs[prevInputIndex].id;
      }
      inputs[inputIndex].value = sanitazedValue;
    } else if (sanitazedValue.length === 1) {
      const nextInputIndex = inputIndex + 1;
      if (nextInputIndex < inputs.length) {
        nextFocusId = inputs[nextInputIndex].id;
      }
      inputs[inputIndex].value = sanitazedValue;
    } else if (sanitazedValue.length > 1) {
      let inputIndexInLoop = inputIndex;
      for (; inputIndexInLoop < Math.min(length, sanitazedValue.length + inputIndex); inputIndexInLoop++) {
        inputs[inputIndexInLoop].value = sanitazedValue[inputIndexInLoop - inputIndex];
      }
      nextFocusId = inputs[Math.min(length - 1, inputIndexInLoop)].id;
    }
    setInputs([...inputs]);
    setFocusInputId(nextFocusId);
    if (focusInputId === nextFocusId) {
      document.getElementById(focusInputId)?.focus({ preventScroll: true });
    }
    if (onOtpChange) {
      const otp = inputs.map((input) => input.value).join("");
      onOtpChange(otp);
    }
  };
  return (
    <Container className={className} dir={"ltr"}>
      {inputs.map((input, index) => (
        <NumberInput
          value={input.value}
          id={input.id}
          key={index}
          onChange={(event) => handleOnChange(event, index)}
          onKeyDown={(event) => handleRemoveKeys(event, index)}
          disabled={props.disabled}
        />
      ))}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const NumberInput = styled(TextInput)<{ disabled?: boolean }>`
  padding-left: 1px;
  padding-right: 1px;

  input {
    width: 34px;
    text-align: center;
    opacity: ${(props) => (props.disabled ? "0" : "1")};
  }

  margin-left: 5px;
  margin-right: 5px;
`;
