import { useState } from "react";
import { logger } from "../../../core/logging/logger";
import { attachmentsManager } from "../../../core/service/services";
import { useObservable } from "../../../utils/observable";
import { useAsyncEffect } from "../../../utils/utils";
import { getUrlFromLink } from "../../BaseUrl";
import { useTransactionLinks } from "../use-transaction-links";
import { Transaction } from "./transaction";
import { TransactionLinks } from "./transaction-links";

export const useAttachments = (transaction: Transaction | undefined) => {
  const attachments = useObservable(attachmentsManager.attachments);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { canGetAttachments } = useTransactionLinks();

  const refresh = async (forceRefresh = false) => {
    await attachmentsManager.clear();
    if (transaction && (canGetAttachments(transaction) || forceRefresh)) {
      try {
        setError("");
        setLoading(true);
        const url = getUrlFromLink(transaction.links, TransactionLinks.GetAttachments);
        await attachmentsManager.refresh(transaction.id, url);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e.message);
        logger.debug("error fetching attachments", e);
      }
    } else {
      attachmentsManager.clear();
    }
  };

  useAsyncEffect(async () => {
    await refresh();
  }, [transaction]);

  return { attachments, refresh, loading, error };
};
