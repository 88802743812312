import { FormFieldValueType } from "../../../mobile/core/data-forms/form-field";
import { FormCollectionInput } from "../../core/data-forms/form-input-types";
import { logger } from "../../core/logging/logger";
import { ApiService } from "../../core/net/api-service";
import { formatCollectionInputs } from "../../utils/format-collection-inputs.ts";
import { InitialOnboarding } from "./onboarding";

export class OnboardingService {
  public constructor(private apiService: ApiService) {}

  public async initiateOnboarding(data: InitialOnboarding) {
    try {
      const response = await this.apiService.instance.post<FormCollectionInput>("/onboarding/initiate", {
        ...data,
        phoneNumber: data.phoneNumber?.number,
      });
      return response.status === 204;
    } catch (e) {
      logger.debug("OnboardingService", "Failed to initiate onboarding", e);
      // console.log(e)
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async getOnboardingFile() {
    try {
      const response = await this.apiService.instance.get("/onboarding/file");
      const formattedInputs = formatCollectionInputs(response.data.form.inputs);

      return {
        ...response.data,
        form: {
          ...response.data.form,
          inputs: formattedInputs,
        },
      };
    } catch (e) {
      logger.debug("OnboardingService", "Failed to get onboarding file", e);
      if (e?.response.data.error.code === 62328) {
        throw "CLIENT_DELEGATE_USER_NOT_ALLOWED";
      } else {
        throw e?.response?.data?.error?.message || e.toString();
      }
    }
  }

  public async updateOnboardingFile(form: {
    [key: string]: {
      [key: string]: FormFieldValueType;
    };
  }) {
    try {
      const response = await this.apiService.instance.patch("/onboarding/file", { form });
      const formattedInputs = formatCollectionInputs(response.data.form.inputs);
      return {
        ...response.data,
        inputs: formattedInputs,
      };
    } catch (e) {
      logger.debug("OnboardingService", "Failed to update onboarding file", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async getAttachmentPreview(attachmentId: string, link?: string) {
    try {
      const response = await this.apiService.instance.get(link || `/documents/${attachmentId}/preview`);
      return response.data;
    } catch (e) {
      logger.debug("OnboardingService", "Failed to get attachment preview", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async submitOnboardingFile() {
    try {
      const response = await this.apiService.instance.post("/onboarding/submit");
      return response.data;
    } catch (e) {
      logger.debug("OnboardingService", "Failed to submit onboarding file", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  // public async confirmRegister(phoneNumber: string, enrollmentId: string) {
  // 	try {
  // 		const response = await this.apiService.instance.post(`/enrollment/${phoneNumber}`, {
  // 			metadata: {
  // 				mode: RegisterMode.Transaction,
  // 				enrollmentId,
  // 			},
  // 		});
  // 		return response.data;
  // 	} catch (e) {
  // 		logger.debug("RegisterService", "Failed to confirm register", e, JSON.stringify(e));
  // 		throw e?.response?.data?.error?.message || e.toString();
  // 	}
  // }

  // public async validateForm(
  // 	phoneNumber: string,
  // 	form: { [key: string]: string | number | boolean },
  // 	redirectUrl: string
  // ) {
  // 	try {
  // 		const response = await this.apiService.instance.post(`/enrollment/${phoneNumber}`, {
  // 			metadata: { mode: RegisterMode.PreAuth },
  // 			data: { form, redirectUrl },
  // 		});
  // 		if (response.status === 200) return { data: response.data as RegisterPreAuthDto, done: true as const };
  // 		else {
  // 			return { data: response.data as RegisterPreAuthErrorDto, done: false as const };
  // 		}
  // 	} catch (e) {
  // 		logger.debug("RegisterService", "Failed to validate register", e);
  // 		throw e?.response?.data?.error?.message || e.toString();
  // 	}
  // }
}
