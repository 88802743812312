import React, { useState } from "react";
import { accountDeleteService, authenticationManager } from "../../../../../shared/core/service/services";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FormTextInput } from "../../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { ACCOUNT_DELETE_MODAL_ID } from "../../../../core/modal/modal-id";
import { Path } from "../../../../core/routing/path";
import { useRTL } from "../../../../domain/language/use-rtl";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { TextField } from "../../../common/data-forms/text-field";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { IconCommunication } from "../../../common/svg/icon-communication";
import { IconKeypad } from "../../../common/svg/icon-keypad";
import { theme } from "../../../styles/theme";
import { OptionCard } from "./option-card";

enum DeletionStep {
  Warning,
  Confirm,
}

export const AccountDeleteModal = () => {
  const { formatMessage } = useIntl();
  const dismissModal = () => Modal.dismiss(ACCOUNT_DELETE_MODAL_ID);
  const { isRTL } = useRTL();
  const history = useHistory();
  const [step, setStep] = useState<DeletionStep>(DeletionStep.Warning);
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [reason, setReason] = useState<FormTextInput>({
    id: "reason",
    value: "",
  } as FormTextInput);

  const goToContact = () => {
    history.replace(Path.BankContact);
    dismissModal();
  };

  const handleDeleteAccount = () => {
    const session = authenticationManager.session.get();
    setSuccess("");
    setError("");
    if (session.clientId && reason.value) {
      accountDeleteService
        .deleteAccount(session.clientId, reason.value)
        .then((e) => {
          setSuccess(formatMessage("accountDeleteScreen.confirm.success"));
        })
        .catch((e) => {
          setError(e);
        });
    }
  };

  return (
    <ModalContainer closeButton id={ACCOUNT_DELETE_MODAL_ID}>
      <>
        <TopRightBackgroundImage $isRTL={isRTL} />
        {step === DeletionStep.Warning && (
          <>
            <Title>{formatMessage("accountDeleteScreen.title")}</Title>
            <Subtitle>{formatMessage("accountDeleteScreen.subtitle")}</Subtitle>
            <OptionsContainer>
              <OptionCard
                title={formatMessage("accountDeleteScreen.options.changeProfile.title")}
                subtitle={formatMessage("accountDeleteScreen.options.changeProfile.description")}
                onClick={() => dismissModal()}
              >
                <IconKeypad color={theme.mainColor()} />
              </OptionCard>
              <OptionCard
                title={formatMessage("accountDeleteScreen.options.unHappy.title")}
                subtitle={formatMessage("accountDeleteScreen.options.unHappy.description")}
                onClick={() => goToContact()}
              >
                <IconCommunication color={theme.mainColor()} />
              </OptionCard>
            </OptionsContainer>
            <TextButton onClick={() => setStep(DeletionStep.Confirm)}>
              {formatMessage("accountDeleteScreen.button")}
            </TextButton>
            <Explanation>{formatMessage("accountDeleteScreen.explanation")}</Explanation>
          </>
        )}
        {step === DeletionStep.Confirm && (
          <>
            <Title style={{ marginBottom: 20, fontSize: 20 }}>
              {formatMessage("accountDeleteScreen.confirm.title")}
            </Title>
            <TextField
              innerStyle={{ paddingBottom: 40 }}
              input={reason}
              placeholder={formatMessage("accountDeleteScreen.confirm.placeholder")}
              onChange={(e) => setReason({ ...reason, value: e })}
              multiline
            />
            {success.length === 0 && error.length === 0 && (
              <>
                <Subtitle style={{ marginTop: 28, marginBottom: 20 }}>
                  {formatMessage("accountDeleteScreen.confirm.subtitle")}
                </Subtitle>
                <Warning>{formatMessage("accountDeleteScreen.confirm.warning")}</Warning>
              </>
            )}
            {success.length > 0 && (
              <Subtitle style={{ color: theme.colors.success[500], marginTop: 28 }}>{success}</Subtitle>
            )}
            {error.length > 0 && <Subtitle style={{ color: theme.colors.error[500], marginTop: 28 }}>{error}</Subtitle>}
            <PrimaryButton
              style={{ margin: "auto", marginBottom: 24, marginTop: 38 }}
              size="S"
              onClick={() => handleDeleteAccount()}
              disabled={reason.value?.length === 0}
            >
              {formatMessage("accountDeleteScreen.confirm.button")}
            </PrimaryButton>
          </>
        )}
      </>
    </ModalContainer>
  );
};

const ModalContainer = styled(RoundedModalContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding-top: 100px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 61px;
  min-width: 400px;
`;

const BackgroundSrc = require("../../../../assets/images/svg/delete-account-background.svg");

const TopRightBackgroundImage = styled.div<{ $isRTL: boolean }>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.$isRTL ? "unset" : 0)};
  left: ${(props) => (props.$isRTL ? 0 : "unset")};
  background-image: url(${BackgroundSrc});
  width: 263px;
  height: 190px;
  -webkit-transform: scaleX(${(props) => (props.$isRTL ? -1 : 1)});
  transform: scaleX(${(props) => (props.$isRTL ? -1 : 1)});
`;

const Title = styled.div`
  ${theme.boldText};
  color: black;
  font-size: 1.5rem;
  line-height: 2.125rem;
  text-align: center;
  margin: 86px 0 12px 0;
`;

const Subtitle = styled.div`
  ${theme.text};
  color: #b1b1b1;
  font-size: 0.9375rem;
  line-height: 1.4375rem;
  text-align: center;
  margin: 8px 50px 16px 50px;
`;

const Warning = styled.div`
  ${theme.boldText};
  color: #000000;
  font-size: 1.0375rem;
  line-height: 1.4375rem;
  text-align: center;
  margin: 8px 50px 16px 50px;
`;

const TextButton = styled.div`
  ${theme.boldText};
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
  margin: 32px 66px 22px 66px;
`;

const Explanation = styled.div`
  ${theme.text};
  color: #aaaaaa;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
  text-align: center;
  margin: 20px 66px 16px 66px;
`;

const OptionsContainer = styled.div``;
