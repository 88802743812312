import { AsYouType, CountryCode } from "libphonenumber-js";
import React, { useState } from "react";
import styled from "styled-components";

import { countries } from "../../../../../shared/assets/countries";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { DefaultButton } from "../../../common/buttons/default-button";
import { MarkdownText } from "../../../common/forms/markdown-text";
import { PhoneInput } from "../../../common/forms/phone-input";
import { theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";

export interface ConnectInputNumerProps {
  currentCountryCode: CountryCode;
  onUpdatePhone: (phone: string) => void;
  onUpdateCountryCode: (countryCode: CountryCode) => void;
  onLostPhoneClick: () => void;
  error: string | undefined;
  initialValue?: string | null;
}

export const ConnectInputNumber = (props: ConnectInputNumerProps) => {
  const { onLostPhoneClick, error, onUpdatePhone, onUpdateCountryCode, initialValue, currentCountryCode } = props;

  const { formatMessage } = useIntl();

  const [countryCode, setCountryCode] = useState(currentCountryCode);
  const [phoneNumber, setPhoneNumber] = useState(initialValue || "");

  const updateCountryCode = (countryCode: CountryCode) => {
    setCountryCode(countryCode);
    onUpdateCountryCode(countryCode);
  };

  const updatePhoneNumber = (phoneNumber: string) => {
    const nationalNumber = "+" + countries[countryCode].phoneCode;
    const internationalPhoneNumber = phoneNumber.startsWith(nationalNumber)
      ? phoneNumber
      : nationalNumber + phoneNumber;
    const formatted = new AsYouType().input(internationalPhoneNumber).substr(nationalNumber.length);
    setPhoneNumber(formatted);
    onUpdatePhone(formatted);
  };

  return (
    <Container>
      <Title>{formatMessage("connectScreen.title")}</Title>
      <Subtitle>{formatMessage("connectScreen.subtitle")}</Subtitle>
      <InputContainer>
        <PhoneInput
          onChangeCountryCode={updateCountryCode}
          onChangePhoneNumber={updatePhoneNumber}
          initialCountryCode={countryCode}
          initialPhone={phoneNumber}
        />
        <TextButton onClick={onLostPhoneClick}>{formatMessage("connectScreen.lostPhoneButton")}</TextButton>
      </InputContainer>
      {error && (
        <ErrorContainer>
          <ErrorTitle>{formatMessage("connectScreen.errorTitle")}</ErrorTitle>
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
`;

const Title = styled(MarkdownText)`
  ${theme.boldText}
  font-size: 2.5rem;
  line-height: 2.875rem;
  align-self: flex-start;
  margin-bottom: 24px;
`;

const Subtitle = styled.span`
  ${theme.text}
  color: #b1b1b1;
  font-size: 0.9375rem;
  margin-bottom: 52px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    max-width: 320px;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  flex-direction: column;
  margin-bottom: 8px;
`;

const ErrorTitle = styled.span`
  ${theme.mediumText};
  font-size: 0.875rem;
  color: #ff4242;
  margin: 8px 0 4px 8px;
`;

const ErrorMessage = styled.span`
  ${theme.text}
  font-size: 0.8125rem;
  color: #ff4242;
  margin-left: 8px;
  margin-bottom: 8px;
`;

const TextButton = styled(DefaultButton)`
  ${theme.mediumText}
  font-size: 0.8125rem;
  align-self: flex-end;
  text-decoration: underline;
  margin-bottom: 20px;
`;
