import { parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js";
import { BasicSecureStorage } from "../../../mobile/core/cache/basic-secure-storage";
import { FormFieldValueType } from "../../../mobile/core/data-forms/form-field";
import { PickerImage } from "../../../mobile/core/imagePicker/picker-image";
import { BasicStorage } from "../../core/cache/basic-storage";
import {
  FileProps,
  FormCollectionInput,
  FormCollectionInputWithHierarchy,
  FormImageInput,
  FormInput,
  FormInputType,
  FormInputWithHierarchy,
} from "../../core/data-forms/form-input-types";
import { ExtraStepFormInput, parseExtraStepFormInput } from "../../core/data-forms/form-parser";
import { isDefined } from "../../utils/assert";
import { ImagesConverter, UploadedImage } from "../../utils/images-converter";
import { Observable } from "../../utils/observable";
import { PincodeSubmission } from "../pincode/pincode";
import { RegisterConfirmationMode, RegisterPreAuthDto, RegisterTypeConfirmation } from "./register-request";
import { RegisterService } from "./register-service";
import { getUbbleRedirectUrl } from "./ubble/ubble-redirect-url";

export interface PendingEnrollment {
  phoneNumber?: PhoneNumber;
  preAuth?: RegisterPreAuthDto;
}

interface PendingEnrollmentDto {
  phoneNumber?: string;
  preAuth?: RegisterPreAuthDto;
}

const PENDING_ENROLLMENT_KEYSTORE_KEY = "pending_enrollment";

export class RegisterManager {
  constructor(
    private registerService: RegisterService,
    private storage: BasicStorage<string | null> | BasicSecureStorage,
    private imagesConverter: ImagesConverter,
  ) {}
  form = new Observable<FormCollectionInputWithHierarchy | null>(null);
  confirmError = new Observable<string | null>(null);
  confirmLoading = new Observable<boolean>(false);
  formStepsAsArray = new Observable<FormCollectionInputWithHierarchy[]>([]);
  extraFormInput = new Observable<ExtraStepFormInput>({ pincodeFormInput: null });
  parsedPhoneNumber = new Observable<PhoneNumber | undefined>(undefined);

  async start(phoneNumber: PhoneNumber) {
    this.parsedPhoneNumber.set(phoneNumber);
    this.savePendingEnrollment({ phoneNumber });
    const form = addHierarchy(await this.registerService.startRegister(phoneNumber.number.substring(1)));
    this.form.set(form);
    this.extraFormInput.set(parseExtraStepFormInput(form));
    this.formStepsAsArray.set(collectCollections(form));
  }

  fillPincode(pincodeSubmission: PincodeSubmission) {
    const form = this.form.get();
    const pincodeFormInput = this.extraFormInput.get().pincodeFormInput;
    if (form !== null && pincodeFormInput !== null) {
      const node = getInput(form, pincodeFormInput.hierarchy);
      node.id = pincodeFormInput.id;
      node.value = { id: pincodeSubmission.id, value: pincodeSubmission.value };
      this.form.set({ ...form });
    }
  }

  fillResponse(input: FormInputWithHierarchy, value: FormFieldValueType, fileProps?: FileProps) {
    const form = this.form.get();
    if (form !== null) {
      getInput(form, input.hierarchy).value = value;
      getInput(form, input.hierarchy).fileProps = fileProps;
      this.form.set({ ...form });
      this.formStepsAsArray.set(collectCollections(form));
    }
  }

  async fillMultiImagesResponse(input: FormInputWithHierarchy, values: (PickerImage | UploadedImage | null)[]) {
    const isReady =
      values.reduce<number>((prev, value) => prev + (value ? 1 : 0), 0) >=
      ((input as FormImageInput).minimumPageCount ?? 1);

    let pdfDocument: string | undefined;
    if (isReady) {
      const filteredValues = values.filter((value) => value);
      pdfDocument = await this.imagesConverter.toPdf(filteredValues);
      if (isDefined(pdfDocument)) {
        pdfDocument = await this.imagesConverter.toBase64(pdfDocument);
      }
    }
    const form = this.form.get();
    if (form !== null) {
      getInput(form, input.hierarchy).values = values;
      getInput(form, input.hierarchy).value = pdfDocument;
      this.form.set({ ...form });
      this.formStepsAsArray.set(collectCollections(form));
    }
  }

  setInputValidity(input: FormInputWithHierarchy, isValid: boolean) {
    const form = this.form.get();
    if (form !== null) {
      getInput(form, input.hierarchy).invalid = !isValid;
      this.form.set({ ...form });
      this.formStepsAsArray.set(collectCollections(form));
    }
  }

  async confirmRegister() {
    const pendingEnrollment = await this.loadPendingEnrollment();
    const enrollmentId = pendingEnrollment?.preAuth?.metadata.enrollmentId;
    const phoneNumber = pendingEnrollment?.phoneNumber;
    if (!phoneNumber) {
      throw new Error("Can't confirm, phoneNumber is undefined");
    }
    if (!enrollmentId) {
      throw new Error("Can't confirm, enrollmentId is undefined");
    }
    const result = await this.registerService.confirmRegister(phoneNumber.number.substring(1), enrollmentId);
    return result;
  }

  async validateForm() {
    const phoneNumber = (await this.loadPendingEnrollment())?.phoneNumber;
    if (!phoneNumber) {
      throw new Error("Can't validate, phoneNumber is undefined");
    }
    const form = this.form.get();
    if (!form) {
      throw new Error("Can't validate, form is null");
    }
    const result = await this.registerService.validateForm(
      phoneNumber.number.substring(1),
      collectValue(form),
      getUbbleRedirectUrl(),
    );
    if (result.done) {
      await this.savePendingEnrollment({ preAuth: result.data });
    }
    return result;
  }

  private async retrieveUbbleUrl() {
    const confirmation = (await this.loadPendingEnrollment())?.preAuth?.metadata.confirmationMode;
    if (
      confirmation?.mode === RegisterConfirmationMode.ValidationNeeded &&
      confirmation.type === RegisterTypeConfirmation.Url
    ) {
      return confirmation.value;
    }
    return null;
  }

  async getUbbleUrl() {
    const url = await this.retrieveUbbleUrl();
    if (isDefined(url)) {
      return url;
    }
    throw new Error("No pending enrollment or ubble URL incorrect");
  }

  async loadPendingEnrollment(): Promise<PendingEnrollment | null> {
    let storedValue;
    try {
      storedValue = await this.storage.read(PENDING_ENROLLMENT_KEYSTORE_KEY);
    } catch (e) {
      storedValue = null;
    }
    if (storedValue) {
      const parsed: PendingEnrollmentDto = JSON.parse(storedValue);
      return parsed.phoneNumber
        ? {
            preAuth: parsed.preAuth,
            phoneNumber: parsePhoneNumberFromString(parsed.phoneNumber),
          }
        : { preAuth: parsed.preAuth };
    }
    return null;
  }

  async clearPendingEnrollment() {
    await this.storage.clear(PENDING_ENROLLMENT_KEYSTORE_KEY);
  }

  private async savePendingEnrollment(enrollment: Partial<PendingEnrollment>) {
    const previousEnrollment = await this.loadPendingEnrollment();
    const phoneNumberSerialized =
      enrollment.phoneNumber?.formatInternational() || previousEnrollment?.phoneNumber?.formatInternational();
    const dto = {
      preAuth: { ...previousEnrollment?.preAuth, ...enrollment.preAuth },
      phoneNumber: phoneNumberSerialized,
    };
    await this.storage.store(JSON.stringify(dto), PENDING_ENROLLMENT_KEYSTORE_KEY);
  }
}

function addHierarchy(input: FormCollectionInput, hierarchy = ""): FormCollectionInputWithHierarchy {
  return {
    ...input,
    hierarchy: `${hierarchy}/${input.id}`,
    inputs: input.inputs.map((child) => {
      if (child.type === FormInputType.Collection) {
        return addHierarchy(child, `${hierarchy}${input.id}/`);
      } else {
        return addHierarchyToInput(child, `${hierarchy}${input.id}`);
      }
    }),
  };
}

function addHierarchyToInput(input: FormInput, hierarchy = ""): FormInputWithHierarchy {
  return { ...input, hierarchy: `${hierarchy}/${input.id}` };
}

function collectCollections(input: FormCollectionInputWithHierarchy): FormCollectionInputWithHierarchy[] {
  //@ts-ignore
  return input.inputs.reduce<FormCollectionInputWithHierarchy[]>((acc, input) => {
    if (input.type !== FormInputType.Collection) {
      return [];
    }
    if (input.id.endsWith("FieldSet")) {
      return [...acc, input];
    }
    return [...acc, ...collectCollections(input as FormCollectionInputWithHierarchy)];
  }, []);
}

export function getInput(root: FormCollectionInput, hierarchy: string) {
  let node = { inputs: [root] } as any;
  const parts = hierarchy.split("/");

  for (const part of parts) {
    const foundNode = node.inputs.find((n: { id: string }) => n.id === part);
    if (!foundNode) {
      throw new Error(`can't find node ${part} in hierarchy ${hierarchy}`);
    }
    node = foundNode;
  }

  return node;
}

function collectValue(input: FormInput) {
  if (input.disabled === true) {
    return null;
  }
  if (input.type === FormInputType.Collection) {
    return input.inputs.reduce((acc, child) => {
      const value = collectValue(child);
      if (value || child.type === FormInputType.Bool) {
        acc[child.id] = collectValue(child);
      }
      return acc;
    }, {} as any);
  } else {
    return input.value;
  }
}
