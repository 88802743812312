import { logger } from "../../core/logging/logger";
import { SavingsApiService } from "../../core/net/savings-api-service";
import { Observable } from "../../utils/observable";
import { SavingDetails } from "./savings";

export class SavingsService {
  public constructor(private apiService: SavingsApiService) {}
  public token = new Observable<string>("");

  public async getSavingsDetails(cbsAccountId): Promise<SavingDetails> {
    try {
      const response = await this.apiService.instance.get(`/contracts/by-account-number/${cbsAccountId}`);
      return response.data;
    } catch (e) {
      logger.debug("Savings Service", "get savings failed", e);
      throw e;
    }
  }
}
