import { css } from "styled-components";
import { configurationManager } from "../../../shared/core/service/services";
import { StaticAssets } from "./custom-assets";

export const fonts = {
  regular: {
    fontWeight: "normal" as const,
  },
  medium: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold" as const,
  },
};

export const theme = {
  mediumText: { ...fonts.medium },
  text: { ...fonts.regular },
  boldText: { ...fonts.bold },
  bodyBlackRegular: { ...fonts.regular, fontSize: 15, color: "#000000" },
  bodyBlackBold: { ...fonts.bold, fontSize: 15, color: "#000000" },
  bodyGreyRegular: { ...fonts.regular, fontSize: 15, color: "#aaaaaa" },
  bodyGreyDarkRegular: { ...fonts.regular, fontSize: 15, color: "#626262" },
  bodySmallGreyDarkMedium: { ...fonts.medium, fontSize: 13, color: "#626262" },
  mainColor: () => configurationManager.getPrimaryColor(),

  mainColorLightBackground: () => configurationManager.getPrimaryColor() + "26",
  colors: {
    gray: {
      50: "#fafafa",
      100: "#f4f4f4",
      200: "#f0f0f0",
      300: "#e0e0e0",
      500: "#b1b1b1",
      700: "#626262",
    },
    warning: {
      300: "#FFECD1",
      500: "#FF9A03",
    },
    success: {
      300: "#D1FFD1",
      500: "#81C241",
    },
    error: {
      300: "#FFD1D1",
      500: "#EE161B",
    },
    info: {
      300: "#B7D5F1",
      500: "#0D71CD",
    },
  },
};

export const shadows = {
  medium: css`
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  `,
  small: css`
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
  `,
};

export const updateTheme = () => {
  const favicon = document.getElementById("favicon") as HTMLAnchorElement | null;
  const favicons = document.getElementsByClassName("custom-favicon") as HTMLCollectionOf<HTMLAnchorElement> | null;
  if (favicons && favicons?.length > 0) {
    [].map.call(favicons, (element: HTMLAnchorElement) => {
      element.href = configurationManager.getFaviconUrl(element.getAttribute("sizes") as "16x16" | "32x32" | "192x192");
    });
  } else if (favicon) {
    favicon.href = configurationManager.getFaviconUrl();
  }
  StaticAssets.logo = configurationManager.getLogoUrl() ?? StaticAssets.logo;
  StaticAssets.registerImage = configurationManager.getRegisterImageUrl() ?? StaticAssets.registerImage;
  StaticAssets.carouselImages = configurationManager.getCarouselUrls() ?? StaticAssets.carouselImages;
};
